import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';


const ExpireDatePicker = ({
  expireDate,
  expireDateAs,
  onChange,
}) => {
  
  const [selectedDate, setSelectedDate] = React.useState();
  const [wasOpened, setWasOpened] = React.useState();

  const handleDateChange = (date) => {
    if (wasOpened)
    {
      onChange({ [expireDateAs]: date })
    }
  };

  const handleOnOpen = () => {
    setWasOpened(true);
  };

  const handleOnClose = () => {
    setWasOpened(false);
  };

  const addNewDate = (event) => {
    onChange({ [expireDateAs]: new Date(event.target.value) }) 
  }

  return (    
    <div style={{width:'140px'}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              format="MM-dd-yyyy"
              variant="inline"              
              value = {expireDate ? moment(expireDate) : moment()}
              clearable
              disablePast
              onChange = { handleDateChange }
              onBlur= { addNewDate } 
              //onKeyDown = { (event) => { if (event.keyCode === 13) { addNewDate(event) } } } 
              onOpen= { handleOnOpen }
              onClose= { handleOnClose }              
            />
      </MuiPickersUtilsProvider>
    </div>
  );
};

ExpireDatePicker.propTypes = {
  expireDate: PropTypes.instanceOf(Date).isRequired,
  expireDateAs: PropTypes.string,
  onChange: PropTypes.func
};

ExpireDatePicker.defaultProps = {
  onChange: () => {},
  expireDateAs: 'expireDate',
  startProps: {},
  
};

export default ExpireDatePicker;
