import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";


const useStyles = makeStyles((theme) => ({
  submit: {
    background: "green"
  },
  fileDropzone: {
    "& .MuiDropzonePreviewList-removeButton": {
      top: "0%",
      left: "40%"
    }
  },

  filePosition: {
    "& .MuiGrid-container": {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      boxSize: "border-box"
    }

  },


  previewImg: {
    height: "100px",
    width: "100px"

  }
}));


export const ModalForm = ({
                            selectedBrand,
                            productTypeName,
                            concentrateTypeName,
                            canabisTypeName,
                            brandListSearch,
                            getBrandId,
                            getProdType,
                            getConcentrat,
                            getCanabisType,
                            getCategory,
                            getImageFile,
                            prodTypes,
                            categoriesTypes,
                            handleConfirm,
                            handleClearState,
                            categoryIds,
                            imageFile
                          }) => {
  const classes = useStyles();
  const [concentrate, setConcentrate] = useState(
    [
      { type: "concentrate", name: "Concentrate" },
      { type: "non_concentrate", name: "No concentrate" }]);
  const [canabis, setCanabis] = useState(
    [
      { type: "Yes", id: "1" },
      { type: "No", id: "0" }]);


  const file = new File(["foo"], "foo.txt", {
    type: "text/plain"
  });

  return (
    <div style={{ width: 400 }}>
      <div>
        <Autocomplete
          id="brand-id"
          clear="true"
          options={brandListSearch}
          getOptionLabel={option => typeof option === "string" ? option : option.label}
          blurOnSelect
          openOnFocus
          onChange={(event, newInputValue) => {
            getBrandId(event, newInputValue);
          }}
          value={selectedBrand}
          renderInput={(params) => (
            <TextField {...params} label="Brand" margin="normal" variant="outlined" />
          )}
        />
      </div>
      <div>
        <Autocomplete
          id="product-type-id"
          options={prodTypes}
          getOptionLabel={option => typeof option === "string" ? option : option.name}
          blurOnSelect
          openOnFocus
          onChange={(event, newInputValue) => {
            getProdType(event, newInputValue);
          }}
          value={productTypeName}
          renderInput={(params) => (
            <TextField {...params} label="Product type" margin="normal" variant="outlined" />
          )}
        />
      </div>
      <div>
        <Autocomplete
          id="concentrate-id"
          options={concentrate}
          getOptionLabel={option => typeof option === "string" ? option : option.name}
          blurOnSelect
          openOnFocus
          onChange={(event, newInputValue) => {
            getConcentrat(event, newInputValue);
          }}
          value={concentrateTypeName}
          renderInput={(params) => (
            <TextField {...params} label="Is concentrate" margin="normal" variant="outlined" />
          )}
        />
      </div>
      <div>
        <Autocomplete
          id="canabis-id"
          options={canabis}
          getOptionLabel={option => typeof option === "string" ? option : option.type}
          blurOnSelect
          openOnFocus
          onChange={(event, newInputValue) => {
            getCanabisType(event, newInputValue);
          }}
          value={canabisTypeName}
          renderInput={(params) => (
            <TextField {...params} label="Is canabis" margin="normal" variant="outlined" />
          )}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <p style={{ fontSize: 20 }}>Category</p>
        {categoriesTypes && categoriesTypes.length > 0 &&
        categoriesTypes.map((item) =>
          <div key={item.id}>
            <label>
            <span>
            <input type="checkbox" name={item.name} id={item.id} onChange={() => getCategory(item.id)}
                   checked={categoryIds.filter(item1 => item1 == item.id).length === 1} multiple />
            </span>
              <span style={{ marginLeft: 10, fontSize: 13 }}>{item.name}</span>
            </label>
          </div>
        )
        }
      </div>
      <div style={{ marginTop: 50 }}>
        <div className={classes.fileDropzone}>
          <div className={classes.filePosition}>
            <DropzoneArea
              filesLimit={20}
              onChange={getImageFile}
              maxFileSize={10000000}
              inputProps={{ name: "file" }}
              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
              initialFiles={imageFile}
              showPreviewsInDropzone={true}
              dropzoneClass={classes.dropZone}
              previewGridClasses={{
                item: classes.preview
              }}
              getPreviewIcon={(file) => {
                return (
                  <img className={classes.previewImg} src={file.data} />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ position: "relative", marginLeft: 100, top: 10 }}>
        <Button style={{ width: 100 }}
                type="button"
                variant="contained"
                color="default"
                onClick={handleClearState}
        >
          Cancel
        </Button>
        <Button style={{ marginLeft: 5, width: 100 }}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleConfirm}
        >
          Update
        </Button>
      </div>
    </div>
  );
};


ModalForm.propTypes = {
  selectedBrand: PropTypes.string,
  productTypeName: PropTypes.string,
  concentrateTypeName: PropTypes.string,
  canabisTypeName: PropTypes.string,
  brandListSearch: PropTypes.arrayOf(PropTypes.object),
  getBrandId: PropTypes.func,
  getProdType: PropTypes.func,
  getConcentrat: PropTypes.func,
  getCanabisType: PropTypes.func,
  getCategory: PropTypes.func,
  getImageFile: PropTypes.func,
  prodTypes: PropTypes.arrayOf(PropTypes.object),
  categoriesTypes: PropTypes.arrayOf(PropTypes.object),
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleClearState: PropTypes.func

};

ModalForm.defaultProps = {
  selectedBrand: "",
  productTypeName: "",
  concentrateTypeName:"",
  canabisTypeName: "",
  brandListSearch: [],
  getBrandId: () => { },
  getProdType: () => { },
  getConcentrat: () => { },
  getCanabisType: () => { },
  getCategory: () => { },
  getImageFile: () => { },
  prodTypes: [],
  categoriesTypes: [],
  handleClose: () => { },
  handleConfirm: () => { },
  handleClearState: ()=>{}
};
