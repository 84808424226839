import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Lodash
import { capitalize } from 'lodash';

// date-fns
import addMonths from 'date-fns/addMonths';
// import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';

// Tubular
import { useTbTable } from 'tubular-react-common';
import { ToolbarOptions } from 'tubular-react';
import DataGrid from '../utils/DataGrid';
import tableColumns from './ReportsColumns';

import TableFilters from './ReportsTableFilters';
import Datepicker from '../utils/Datepicker';

import metrc from './apiClient';

const ITEMS_PER_PAGE = 25;

const Reports = ({ onDateRangeChange, dateRange }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ successful: true, failed: true });
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const tbTableInstance = useTbTable(tableColumns, reports, {
    pagination: {
      itemsPerPage: ITEMS_PER_PAGE,
      page: 0,
    },
  });

  const toolbarOptions = new ToolbarOptions({
    title: 'Reports',
    rowsPerPageOptions: [],
    itemsPerPage: ITEMS_PER_PAGE,
    advancePagination: false,
    searchText: false,
    topPager: false,
    customItems: [
      <TableFilters
        filters={filters}
        onChange={(value) => setFilters(value)}
      />,
      <Datepicker
        selectedStartDate={dateRange.startDate}
        selectedEndDate={dateRange.endDate}
        onChange={onDateRangeChange}
      />,
    ],
  });

  const updateList = (list) => {
    setReports(
      list.map((item) => ({
        ...item,
        created_at_format: capitalize(
          formatRelative(new Date(item.created_at), new Date())
        ),
        updated_at_format: capitalize(
          formatRelative(new Date(item.updated_at), new Date())
        ),
        statusComponent: (() => (
          <div>
            <span
              style={{
                display: 'inline-block',
                width: 16,
                height: 16,
                borderRadius: 10,
                marginRight: 6,
                backgroundColor: item.success ? 'green' : 'red',
              }}
            />
            {capitalize(item.status)}
          </div>
        ))(),
      }))
    );
  };

  useEffect(() => {
    async function fetchReports() {
      setLoading(true);
      const {
        data: { list, pages, count },
      } = await metrc.reports({
        filters,
        dateRange,
        page: page + 1,
      });
      updateList(list);
      setLoading(false);
      setCount(count);
    }

    fetchReports();
  }, [dateRange, filters, page]);

  useEffect(() => {
    onDateRangeChange({
      startDate: new Date(),
      endDate: addMonths(new Date(), 1),
    });
  }, []);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <DataGrid
        tbTableInstance={tbTableInstance}
        gridName="reports"
        columns={tableColumns}
        dataSource={reports}
        isLoading={loading}
        toolbarOptions={toolbarOptions}
        onChangePage={onPageChange}
        page={page}
        count={count}
      />
    </div>
  );
};

Reports.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default Reports;
