import React, {useState, useEffect} from 'react'
import s from './Print.module.css'


const Print = ({columns, datasource})=>{
  const [cool, setCool]=useState([])
  const [daat, setDaat]=useState([])
  const [c, setC]=useState([])
let k=0

  const dataOfPrint=()=>{
    setC(columns.slice(0, columns.length-2))
    const row = datasource.map((r, index)=>{
    
      return [
        {value: r.name, id: (Math.floor(Math.random() * 10))},
        {value: r.brand_name, id: (Math.floor(Math.random() *20))},
        {value: r.categories_name, id: (Math.floor(Math.random() *30))},
        {value: r.total_quantity, id: (Math.floor(Math.random() *40))},
        {value: r.purchase_price, id: (Math.floor(Math.random() *50))},  
        {value: r.net_weight, id: (Math.floor(Math.random() *60))},
        {value: r.unit_price, id: (Math.floor(Math.random() *70))}
      ]
    })
    setDaat(row)
    
      
       const col = c.map((c, index)=>{
         return(
           {title: c.name, id: index}
           
           ) })
           setCool(col)
           
          }

  useEffect(()=>{
    dataOfPrint()
  },[columns])
  
  return (
    <div style ={{textAlign: 'center', width: "auto" }}>
    <table   className = {s.custom} className={s.page}>
          <thead>
               <tr key= {'hheh'}style ={{fontSize: '12px'}} >
               
                   {cool.map( (w, index) => {
                     
                     
                     return (
                       <th key ={w.id}>{`${w.title}`}</th>
                      
                       )
                      })} 
                </tr>
                     
             { daat.map((r,)=>{
                                        
                              return (
                                
                                <tr  key ={r.id+k, k=k+2.5} style ={{fontSize: '12px', height: 40, marginRight: 0}}>
                                <td  style ={{textAlign: 'left'}} key ={Math.random() * r.id}>{r[0].value}</td> 
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+3.5}>{r[1].value}</td>
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+4.5}>{r[2].value}</td>
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+5.5}>{r[3].value}</td>
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+6.5}>{r[4].value}</td>
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+7.5}>{r[5].value}</td>
                                <td  style ={{textAlign: 'center'}} key ={r.id, k=k+8.5}>{r[6].value}</td>
                                </tr>
                                
                              )      
                               })}
                            
                  
                 
 
          </thead>
      </table>
   </div>  
  )
  };

  export default Print