import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AlertUI from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  alert: {
    position: 'fixed',
    right: 20,
    top: 20,
    zIndex: 9999999
  }
});

const Alert = ({ message, severity, clear, timeToShow }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      clear();
    }, timeToShow);
  }, [message]);

  return (
    !!message && (
      <AlertUI className={classes.alert} severity={severity}>
        {message}
      </AlertUI>
    )
  );
};

Alert.defaultProps = {
  timeToShow: 6000,
  severity: 'success',
  message: ''
};

Alert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  clear: PropTypes.func.isRequired,
  timeToShow: PropTypes.number
};

export default Alert;
