import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useResolutionSwitch } from 'uno-react';
import { GridToolbar } from 'tubular-react/dist/Toolbar/GridToolbar';
import { ToolbarOptions } from 'tubular-react/dist/Toolbar/ToolbarOptions';
import { DataGridTable } from 'tubular-react';
import { MobileDataGridTable } from 'tubular-react/dist/DataGrid/MobileDataGridTable';

import useTbTable from './useTbTable';
import Paginator from './Paginator';
import Wrapper from '../Wrapper';

const useStyles = makeStyles({
  linearProgress: {
    // marginTop: '-10px',
    height: '20px',
  },
  root: {
    overflowX: 'auto',
    width: '100%',
  },
});

const timeout = 400;

export default ({
  columns,
  dataSource,
  deps,
  footerComponent,
  gridName,
  count,
  page,
  mobileBreakpointWidth = 800,
  onError = () => {},
  onRowClick,
  rowComponent,
  rowMobileComponent,
  storage,
  isLoading,
  toolbarOptions = new ToolbarOptions(),
  tbTableInstance,
  detailComponent,
  onChangePage,
}) => {
  const classes = useStyles({});

  tbTableInstance =
    tbTableInstance ||
    useTbTable(columns, dataSource, {
      callbacks: { onError },
      componentName: gridName,
      deps,
      pagination: {
        itemsPerPage: toolbarOptions.itemsPerPage,
      },
      storage,
    });

  const [isMobileResolution] = useResolutionSwitch(
    mobileBreakpointWidth,
    timeout
  );

  if (isMobileResolution) {
    toolbarOptions.SetMobileMode();

    return (
      <Paper className={classes.root}>
        <GridToolbar
          toolbarOptions={toolbarOptions}
          tbTableInstance={tbTableInstance}
          gridName={gridName}
        />
        <div className={classes.linearProgress} data-testid="linear-progress">
          {tbTableInstance.state.isLoading && <LinearProgress />}
        </div>
        <MobileDataGridTable
          tbTableInstance={tbTableInstance}
          onRowClick={onRowClick}
          rowComponent={rowMobileComponent}
        />
        <Paginator
          advancePagination={toolbarOptions.advancePagination}
          rowsPerPageOptions={toolbarOptions.rowsPerPageOptions}
          tbTableInstance={tbTableInstance}
          count={count}
          page={page}
          onChangePage={onChangePage}
        />
      </Paper>
    );
  }

  const paginator = (position) => (
    <Table data-testid={`${position}-paginator`}>
      <TableHead>
        <TableRow>
          <Paginator
            advancePagination={toolbarOptions.advancePagination}
            rowsPerPageOptions={toolbarOptions.rowsPerPageOptions}
            tbTableInstance={tbTableInstance}
            count={count}
            page={page}
            onChangePage={onChangePage}
          />
        </TableRow>
      </TableHead>
    </Table>
  );

  return (
    <Paper className={classes.root}>
      <Wrapper>
        <GridToolbar
          gridName={gridName}
          toolbarOptions={toolbarOptions}
          tbTableInstance={tbTableInstance}
        />
        <div className={classes.linearProgress} data-testid="linear-progress">
          {(tbTableInstance.state.isLoading || isLoading) && <LinearProgress />}
        </div>
        <DataGridTable
          tbTableInstance={tbTableInstance}
          rowComponent={rowComponent}
          footerComponent={footerComponent}
          detailComponent={detailComponent || null}
          onRowClick={onRowClick}
        />
        {toolbarOptions.bottomPager && paginator('bottom')}
      </Wrapper>
    </Paper>
  );
};
