import { ColumnModel, ColumnDataType } from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: true,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('status', 'Status', 'String', { sortable: false }),
  createColumnModel('id', 'Store ID', 'String', { isKey: true }),
  createColumnModel('name', 'Store Name', 'String'),
  createColumnModel('orders_count', 'Orders Count', 'Numeric', {}),
  createColumnModel('locations_count', 'Locations Count', 'Numeric'),
  createColumnModel('twilio_usage', 'SMS Delivered', 'Numeric')
];
