import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isPlainObject } from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const AutocompleteForm = ({
  options,
  onSubmit,
  labelAs,
  btnLabel,
  inputLabel,
  row,
  wrapperStyle,
  multiple,
  valueAs,
  inputType,
  inputProps,
  autoFocus,
  onChange
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e, value) => {
    const newValue = value || e.target.value;

    setSelectedOption(newValue);
    onChange(isPlainObject(newValue) ? newValue[valueAs] : newValue);
  };

  const onFormSubmit = e => {
    e.preventDefault();

    onSubmit(
      isPlainObject(selectedOption) ? selectedOption[valueAs] : selectedOption
    );

    return false;
  };

  const formControlStyles = row
    ? {
        flexDirection: 'row',
        alignItems: 'center'
      }
    : {};

  return (
    <div style={wrapperStyle}>
      <form
        onSubmit={onFormSubmit}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <FormControl style={formControlStyles}>
          {inputType === 'autocomplete' ? (
            <Autocomplete
              options={options}
              onChange={handleChange}
              getOptionLabel={option => option[labelAs]}
              multiple={multiple}
              renderInput={params => (
                <TextField
                  {...params}
                  autoFocus={autoFocus}
                  style={{ minWidth: 160 }}
                  label={inputLabel}
                  onBlur={onFormSubmit}
                />
              )}
            />
          ) : (
            <TextField
              autoFocus={autoFocus}
              onChange={handleChange}
              label={inputLabel}
              style={{ minWidth: 160 }}
              type={inputType}
              inputProps={inputProps}
              onBlur={onFormSubmit}
            />
          )}
        </FormControl>
      </form>
    </div>
  );
};

AutocompleteForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  btnLabel: PropTypes.string,
  inputLabel: PropTypes.string,
  labelAs: PropTypes.string,
  row: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  multiple: PropTypes.bool,
  autoFocus: PropTypes.bool,
  valueAs: PropTypes.string
};

AutocompleteForm.defaultProps = {
  options: [],
  btnLabel: 'Submit',
  onChange: () => {},
  onSubmit: () => {},
  inputLabel: 'Select',
  labelAs: 'name',
  row: false,
  wrapperStyle: {},
  multiple: false,
  valueAs: 'id',
  autoFocus: true
};

export default AutocompleteForm;
