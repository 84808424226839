import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { fromPairs, sortBy } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import OrderOriginForm from './OrderOriginForm';

import Table from './utils/Table';

const useStyles = makeStyles(theme => ({
  tableCell: {
    fontSize: 15
  },
  wrapper: {},
  heading: {
    marginBottom: 16
  },
  form: {
    fontSize: 16,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
      fontSize: 16
    }
  },
  rowLoading: {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none'
  },
  tablePagination: {
    display: 'block'
  }
}));

const StoreOrderOrigins = ({ orderOriginsData }) => {
  const tableAttributes = [
    { label: '#', key: 'id', width: 100 },
    {
      label: 'Origin',
      key: 'origin',
      default: '[No origin]'
    },
    {
      label: 'Actions',
      width: 100,
      align: 'right',
      value: ({ id, store_id, active }) => {
        // Only can delete origins specific to store
        if (!store_id) return null;

        return (
          <div>
            {/* <IconButton color="secondary" onClick={() => deleteOrderOrigin(id)}> */}
            {/*   <DeleteForeverIcon fontSize="large" /> */}
            {/* </IconButton> */}
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={() => toggleOrderOrigin(id, !active)}
                  value={`active-${id}`}
                />
              }
              label={active ? 'Active' : 'Deactive'}
            />
          </div>
        );
      }
    }
  ];

  const classes = useStyles();
  const [orderOrigins, setOrderOrigins] = useState(orderOriginsData);
  const [originsLoading, setOriginsLoading] = useState([]);
  const onFormSubmit = newOrderOrigin => {
    setOrderOrigins(sortBy([newOrderOrigin, ...orderOrigins], 'id'));
  };
  const toggleOriginLoading = (id, add) => {
    const index = orderOrigins.indexOf(id);
    if (!add) return setOriginsLoading(originsLoading.splice(index, 1));
    setOriginsLoading([...originsLoading, id]);
  };
  const deleteOrderOrigin = async id => {
    toggleOriginLoading(id, true);
    await axios.delete(`/order_origins/${id}`);
    setOrderOrigins(orderOrigins.filter(origin => origin.id !== id));
    toggleOriginLoading(id, false);
  };
  const toggleOrderOrigin = async (id, active) => {
    toggleOriginLoading(id, true);
    const { data: updatedOrderOrigin } = await axios.put(
      `/order_origins/${id}`,
      {
        active
      }
    );
    setOrderOrigins([
      ...orderOrigins.map(orderOrigin =>
        orderOrigin.id === id ? updatedOrderOrigin : orderOrigin
      )
    ]);
    toggleOriginLoading(id, false);
  };
  return (
    <div className={classes.wrapper}>
      <h2 className={classes.heading}>Order Origins</h2>
      <OrderOriginForm
        classes={{ form: classes.form }}
        onSubmit={onFormSubmit}
      />
      <Table
        data={orderOrigins}
        havePaging
        attributes={tableAttributes}
        tableCellProps={{
          classes: { body: classes.tableCell, head: classes.tableCell }
        }}
        tableRowProps={fromPairs(
          originsLoading.map(i => [
            i,
            { classes: { root: classes.rowLoading } }
          ])
        )}
        tablePaginationProps={{ classes: { root: classes.tablePagination } }}
      />
    </div>
  );
};

StoreOrderOrigins.propTypes = {
  orderOriginsData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default StoreOrderOrigins;
