import { ColumnModel, ColumnDataType } from 'tubular-common';
import { get, capitalize } from 'lodash';
const allow_sync_metrc_transfer = get(gon, 'store.allow_sync_metrc_transfer');
const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

const columns2 = allow_sync_metrc_transfer ? [
  createColumnModel('status_component', 'Status'),
  createColumnModel('id', '#', 'Number'),
  //createColumnModel('id', '#', 'Number',{ visible: true, isKey: true }),
  createColumnModel('received_date_time', 'ETA', 'DateTime'),
  createColumnModel('package_count', 'Packages', 'Number'),
  createColumnModel('shipper_facility_name', 'Shipper Facility'),
  createColumnModel('actions', 'Actions'),
  createColumnModel('sync', 'Sync Manifest'),
  createColumnModel('hide', 'Hide')
] :  [
  createColumnModel('status_component', 'Status'),
  createColumnModel('id', '#', 'Number'),
  //createColumnModel('id', '#', 'Number',{ visible: true, isKey: true }),
  createColumnModel('received_date_time', 'ETA', 'DateTime'),
  createColumnModel('package_count', 'Packages', 'Number'),
  createColumnModel('shipper_facility_name', 'Shipper Facility'),
  createColumnModel('actions', 'Actions'),
  
];

export default columns2;
