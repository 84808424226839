import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';

import { ToolbarOptions, DataGrid } from 'tubular-react';

import columns from './ProductLogColumns';

import metrc from './apiClient';

const processData = row => ({
  ...row,
  changed_by_formatted: row.changed_by || 'System',
  quantity_formatted: row.quantity > 0 ? `+${row.quantity}` : `${row.quantity}`,
  transfer_id_comp: (() => (
    <a href={`/metrc/intake/delivery/${row.transfer_id}`}>
      {row.transfer_id}
    </a>
  ))(),
});

const sortData = (a, b) => new Date(b.created_at) - new Date(a.created_at);

const ProductLog = ({ productId }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const {
        data: { list }
      } = await metrc.productLogs({ productId });
      setLogs(list.map(processData).sort(sortData));
    } catch (err) {
      console.log(`err: `, err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toolbarOptions = new ToolbarOptions({
    title: 'Inventory Log',
    searchText: false,
    rowsPerPageOptions: [],
    advancedPagination: false,
    topPager: false,
    bottomPager: false
  });

  return (
    <div>
      {loading ? (
        <LinearProgress />
      ) : (
        Array.isArray(logs) && (
          <DataGrid
            dataSource={logs}
            columns={columns}
            toolbarOptions={toolbarOptions}
          />
        )
      )}
    </div>
  );
};

ProductLog.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default ProductLog;
