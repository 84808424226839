import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';

import { Stepper } from './OrderStatusPageStyled';

export default function OrderStatusStepper({ statuses, status }) {
  const activeStep = statuses.map(item => item.label).indexOf(status);
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      height: 22,
      alignItems: 'center'
    },
    active: {
      color: 'red'
    },
    circle: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      backgroundColor: 'silver'
    },
    completed: {
      color: 'white',
      zIndex: 1,
      fontSize: 18
    },
    circleCompleted: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      backgroundColor: '#3f51b5',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  const QontoStepIcon = props => {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div>
        {completed || active ? (
          <div className={classes.circleCompleted}>
            <Check className={classes.completed} />
          </div>
        ) : (
          <div className={classes.circle} />
        )}
      </div>
    );
  };
  const steps = statuses.map(step => (
    <Step key={step.label}>
      <StepLabel StepIconComponent={QontoStepIcon}>{step.label}</StepLabel>
    </Step>
  ));
  const orientation = useMediaQuery('(max-width: 768px)')
    ? 'vertical'
    : 'horizontal';

  return (
    <div>
      <Stepper orientation={orientation} activeStep={activeStep}>
        {steps}
      </Stepper>
    </div>
  );
}

OrderStatusStepper.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired,
  status: PropTypes.string.isRequired
};
