import 'core-js/stable';
import 'regenerator-runtime/runtime';
/* eslint no-console:0 */

import WebpackerReact from 'webpacker-react';
import OrderStatusPage from '../components/OrderStatusPage';
import OrderAssignDriver from '../components/OrderAssignDriver';
import PieChart from '../components/PieChart';

// Metrc
import MetrcInventoryIntake from '../components/Metrc/InventoryIntake';
import MetrcDelivery from '../components/Metrc/Delivery';
import MetrcProduct from '../components/Metrc/Product';
import MetrcIndex from '../components/Metrc/index';
import MetrcInventory from '../components/Metrc/Inventory';
import MetrcOrderLogStatus from '../components/Metrc/OrderLogStatus';
import MetrcProductLog from '../components/Metrc/ProductLog';
import MetrcMergeProduct from '../components/Metrc/MergeProduct';
import MetrcUpdateInventory from '../components/Metrc/UpdateInventory';
import MetrcUpdateQuantityForm from '../components/Metrc/UpdateQuantityForm';
import MetrcUpdateProductExpierDate from '../components/Metrc/UpdateProductExpierDate';

import ProductsList from '../components/Products/ProductList';
import ImportPosCustomers from '../components/ImportPosData/ImportCustomers';
import ImportPosEmployees from '../components/ImportPosData/ImportEmployees';
import ImportPosProducts from '../components/ImportPosData/ImportProducts';
import ImportPosProductsQuantity from '../components/ImportPosData/ImportProductsQuantity';
import ImportPosOrders from '../components/ImportPosData/ImportOrders';

import StoreOrderOrigins from '../components/StoreOrderOrigins';
import SuperAdmin from '../components/SuperAdmin/SuperAdmin';
import SalesChart from '../components/SalesChart';

WebpackerReact.setup({
  OrderStatusPage,
  OrderAssignDriver,
  StoreOrderOrigins,
  SuperAdmin,
  PieChart,
  SalesChart,
  'Metrc/Index': MetrcIndex,
  'Metrc/InventoryIntake': MetrcInventoryIntake,
  'Metrc/Delivery': MetrcDelivery,
  'Metrc/Product': MetrcProduct,
  'Metrc/OrderLogStatus': MetrcOrderLogStatus,
  'Metrc/Inventory': MetrcInventory,
  'Metrc/ProductLog': MetrcProductLog,
  'Metrc/UpdateQuantityForm': MetrcUpdateQuantityForm,
  'Metrc/UpdateProductExpierDate' : MetrcUpdateProductExpierDate,
  'Metrc/UpdateInventory': MetrcUpdateInventory,
  'Metrc/MergeProduct': MetrcMergeProduct,
  'Products/List': ProductsList,
  'ImportPosData/ImportCustomers': ImportPosCustomers,
  'ImportPosData/ImportEmployees': ImportPosEmployees,  
  'ImportPosData/ImportProducts': ImportPosProducts,
  'ImportPosData/ImportProductsQuantity': ImportPosProductsQuantity,
  'ImportPosData/ImportOrders': ImportPosOrders
});
