import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import Button from '@material-ui/core/Button';

import AutocompleteForm from '../AutocompleteForm';
import { linkStyle } from '../../styles/helpers';

const TableSelect = ({
  productId,
  productMetrcLabel,
  productQuantity,
  selectedValue,
  options,
  onSubmit,
  label,
  inputType,
  inputProps,
  valueAs,
  handleUpdate,
  multiple,
}) => {
  const [editMode, setEditMode] = useState(false);

  const forceRefresh = () => setRefreshCount(refreshCount + 1);

  const update = async (newValue) => {
    setEditMode(false);
    if (handleUpdate) {
      handleUpdate(newValue);
    } else {
      const { data } = await axios.put(
        `/products/provider_id/${productId}/json`,
        {
          metrc_label: productMetrcLabel,
          quantity: productQuantity,
          product: {
            [valueAs]: newValue,
            location_quantities: [],
          },
        }
      );
      onSubmit(data);
    }
  };

  const onLabelClick = (e) => {
    toggleEditMode(e, true);
  };

  const toggleEditMode = (e, mode = !editMode) => {
    e.preventDefault();
    setEditMode(mode);
  };

  return (
    /* eslint-disable */
    <div onClick={(e) => e.stopPropagation()}>
      {editMode ? (
        <>
          <AutocompleteForm
            btnLabel="Update"
            inputLabel={`Input ${label}`}
            options={options}
            multiple={multiple}
            onSubmit={update}
            inputType={inputType}
            inputProps={inputProps}
          />
        </>
      ) : (
        /* eslint-disable */
        <button onClick={onLabelClick} style={linkStyle}>
          <div>{selectedValue || `[No ${label}]`}</div>
        </button>
      )}
    </div>
  );
};

TableSelect.propTypes = {
  productId: PropTypes.number.isRequired,
  selectedValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ).isRequired,
  onSubmit: PropTypes.func,
  label: PropTypes.string,
  inputType: PropTypes.string,
  multiple: PropTypes.bool,
};
TableSelect.defaultProps = {
  selectedValue: '-',
  label: '',
  inputType: 'text',
  inputProps: {},
  onSubmit: () => {},
  multiple: false,
};

export default TableSelect;
