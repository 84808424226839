import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { get, invoke, isEmpty } from 'lodash';

import AutocompleteProduct from './AutocompleteProduct';

import api from './apiClient';

const MapProductForm = ({ product, showAlert, afterSubmit, style, forceFetchData }) => {
  const [payload, setPayload] = useState({});
  const autocompleteRef = useRef(null);

  useEffect(() => {
    setPayload(state => ({ ...state, fromProductId: product.store_prod_id }));
  }, [product]);

  const handleProductSelect = value => {
    if (!value) return;

    setPayload(state => ({
      ...state,
      toProductIds: value ? [value] : null
    }));
  };

  const submitForm = async () => {
    try {

      const { data } = await api.addMapping({...payload, toProductIds: [+payload.fromProductId],fromProductId:payload.toProductIds[0].toString() });
      await api.processMapping();
      //window.location.reload();
      showAlert({ severity: 'success', msg: 'Product mapped successfuly' });
      //autocompleteRef.current.setState({ searchText: ' ' })
    } catch (err) {
      
      const msg = get(err, 'response.data.errors', ['Request failed']).join(
        ' '
      );
      showAlert({ severity: 'error', msg });
    } finally {
      forceFetchData();
      afterSubmit();
    }
  };

  useEffect(() => {
    if (isEmpty(payload.toProductIds)) return;

    submitForm();
  }, [payload.toProductIds]);

  return (
    <>
      <AutocompleteProduct style={style} onSelect={handleProductSelect} extraParams={{ref: autocompleteRef}} />
    </>
  );
};

MapProductForm.defaultProps = {
  style: { width: 200 },
  afterSubmit: () => {}
};

export default MapProductForm;
