import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import axios from 'axios';

import {
	Typography,
	Button,
	LinearProgress,
	Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';

import styles from '../styles/ImportData.module.css';

const ImportProducts = ({}) => {
	const [file, setFile] = useState(null);
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const onFileUpload = loadedFiles => {
		if (isEmpty(loadedFiles)) return;

		const formData = new FormData();
		formData.append('file', loadedFiles[0]);

		setFormData(formData);
		setFile(loadedFiles[0]);
	};

	const handleFormSubmit = async e => {
		try {
			e.preventDefault();

			setIsLoading(true);

			await axios.post(`/import_pos_data/import-product`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});

			setIsLoading(false);
			setShowAlert(true);

			return false;
		} catch (err) {
			console.log(`err: `, err);
		}
	};

	const handleCloseAlert = () => setShowAlert(false);

	return (
		<div>
			<Snackbar
				open={showAlert}
				autoHideDuration={6000}
				onClose={handleCloseAlert}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert onClose={handleCloseAlert} severity="success">
					Products imported successfully
				</Alert>
			</Snackbar>
			<div>
				<div>
					<Typography variant="h5" gutterBottom>
						Upload Excel file to import products
					</Typography>
				</div>
				<div className={styles.content}>
					<form onSubmit={handleFormSubmit}>
						{isLoading && <LinearProgress />}
						<DropzoneArea
							onChange={onFileUpload}
							inputProps={{
								name: 'file'
							}}
							filesLimit={1}
							maxFileSize={20500000}
							acceptedFiles={['.xls', '.xlsx','.csv']}
							alertSnackbarProps={{
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right'
								}
							}}
						/>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className={styles.btnSubmit}
						>
							Upload
						</Button>
					</form>
				</div>
			</div>
		</div>
	);
};
export default ImportProducts;
