import { ColumnModel, ColumnDataType } from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('transfer_id_component', 'Manifest #'),
  //createColumnModel('transfer_id_component', 'Manifest #','Number',{ visible: true, isKey: true }),
  createColumnModel('product_id', 'Product ID'),
  createColumnModel('product_name_component', 'Product Name'),
  createColumnModel('brand'),
  createColumnModel('quantity'),
  createColumnModel('categories_names', 'Category'),
  createColumnModel('actions')
];
