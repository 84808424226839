import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ReportsTableFilters = ({ filters, onChange }) => {
  const toggleFilter = name => {
    onChange({
      ...filters,
      [name]: !filters[name]
    });
  };

  return (
    <div style={{ marginRight: 32, marginTop: 12 }}>
      Filters:
      <FormGroup row>
        <FormControlLabel
          label="Successful"
          onChange={() => toggleFilter('successful')}
          control={<Checkbox checked={filters.successful} />}
        />
        <FormControlLabel
          label="Failed"
          onChange={() => toggleFilter('failed')}
          control={<Checkbox checked={filters.failed} />}
        />
      </FormGroup>
    </div>
  );
};

ReportsTableFilters.propTypes = {
  filters: PropTypes.shape({
    successful: PropTypes.bool,
    failed: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportsTableFilters;
