import { ColumnModel, ColumnDataType } from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('statusComponent', 'Status'),
  createColumnModel('order_id', 'Order ID', 'String', { isKey: true }),
  createColumnModel('created_at_format', 'Completed At'),
  createColumnModel('updated_at_format', 'Reported At')
];
