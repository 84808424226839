import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TablePagination from '@material-ui/core/TablePagination';
import { useResolutionSwitch } from 'uno-react';
import { AdvancePaginationActions } from 'tubular-react/dist/Pagination/AdvancePaginationActions';
import Lang from 'tubular-react/dist/utils/Lang';

const useStyles = makeStyles({
  caption: {
    flexShrink: 1,
    height: '55px',
  },
  root: {
    height: '75px',
    maxWidth: '95%',
  },
});

const outerWidth = 800;
const timeout = 400;

const message = (totalRecordCount, filteredRecordCount) => ({
  from,
  to,
  count,
}) =>
  totalRecordCount === filteredRecordCount
    ? Lang.translate('Pages', from, to, count)
    : filteredRecordCount === 0
    ? Lang.translate('NoRecords')
    : Lang.translate('TotalRecords', from, to, count, totalRecordCount);

export default function Paginator({
  tbTableInstance,
  rowsPerPageOptions,
  advancePagination,
  count,
  page,
  onChangePage = () => {},
}) {
  const [isMobileResolution] = useResolutionSwitch(outerWidth, timeout);
  const classes = useStyles({});
  const { state, api } = tbTableInstance;

  if (!state.itemsPerPage) {
    return null;
  }

  // const filteredCount = count || state.filteredRecordCount;
  // const totalCount = count || state.totalRecordCount;

  const newProps = {
    count,
    labelDisplayedRows: message(count, count),
    onChangePage: (_e, page) => {
      onChangePage(page);
    },
    onChangeRowsPerPage: (e) => {},
    page,
    rowsPerPage: state.itemsPerPage,
    rowsPerPageOptions: rowsPerPageOptions || [10, 20, 50],
  };

  // eslint-disable-next-line react/display-name
  newProps.ActionsComponent = () => (
    <AdvancePaginationActions
      count={newProps.count}
      isAdvanced={advancePagination}
      isLoading={newProps.isLoading}
      onChangePage={newProps.onChangePage}
      page={newProps.page}
      rowsPerPage={newProps.rowsPerPage}
    />
  );

  return (
    <TablePagination
      classes={{
        caption: isMobileResolution && classes.caption,
        root: classes.root,
      }}
      {...newProps}
    />
  );
}
