import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import { get } from 'lodash';
import { debounce } from '../utils';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const AutocompleteProduct = ({
	onSelect,
	showAlert,
	provider,
	style,
	valueAs,
  extraParams
}) => {
	const [products, setProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const updateProducts = async ({ query, filter = { provider } }) => {
		try {
			setIsLoading(true);
			const { data } = await axios.get(`/products/search`, {
				params: {
					query,
					...filter
				}
			});

			if (!Array.isArray(data)) throw new Error();

			setProducts(data);
			setIsLoading(false);
		} catch (err) {
			console.log(`err: `, err);
			showAlert({
				msg: get(err, 'response.data.error', 'Request failed'),
				severity: 'error'
			});
		}
	};

	const handleInputChange = ({ e, value }) => {
		if (!value) return;

		updateProducts({ query: value, filter: { provider } });
	};

	const handleValueChange = (e, value) => {
		const selected = valueAs ? get(value, valueAs, null) : value;
		onSelect(selected, e);
	};

	return (
		<>
			<Autocomplete
				options={products}
				id="select-products"
				getOptionLabel={option => option.label}
				onChange={handleValueChange}
				clearOnBlur={false}
				loading={isLoading}
				onInputChange={debounce(
					(e, value) => handleInputChange({ e, value }),
					500
				)}
				renderInput={params => (
					<TextField {...params} label="Select Metrc Product" />
				)}
				style={style}
        {...extraParams}
			/>
		</>
	);
};

AutocompleteProduct.defaultProps = {
	provider: 'metrc',
	onSelect: () => {},
	showAlert: () => {},
	style: { width: 200 },
	valueAs: 'value'
};

export default AutocompleteProduct;
