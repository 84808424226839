import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Global, css } from '@emotion/core';

import { capitalize, get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import lightBlue from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';

import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';
import * as ActionCable from 'actioncable';
// Tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import dayjs from 'dayjs';

import {
  Wrapper,
  Card,
  CardHeader,
  Chip,
  TableWrapper,
  Typography,
  CardLogo,
  GridLogo
} from './OrderStatusPageStyled';
import { Table } from './utils';
import Stepper from './OrderStatusStepper';

const currencyFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD'
});

const orderItemAttributes = [
  {
    label: '',
    value: ({ product: { name } }) =>
      name
        .replace(/_/g, ' ')
        .split(' ')
        .map(text => capitalize(text))
        .join(' ')
  },
  {
    label: '',
    key: 'quantity'
  },
  {
    label: '',
    value: ({ cost }) => cost
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));
const OrderStatusPage = ({
  orderProp,
  orderStatuses,
  storeLogo,
  // eslint-disable-next-line react/prop-types
  etaOrder,
  orderItems,
  statusPlace,
  isCatalyst,
  hostUrl,
  // eslint-disable-next-line react/prop-types
  state
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [dataValue, setDataValue] = useState(0);

  const [order, setOrder] = useState(orderProp);
  const [etaOrderState, setEtaOrderState] = useState(etaOrder);
  const [orderStatus, setOrderStatus] = useState(state);
  const [open, setOpen] = React.useState(false);
  const [rating, setRaiting] = React.useState(0);
  const [remark, setRemark] = React.useState('');
  const classes = useStyles();

  let sequenceText;
  if (order.sequence_num == 1) {
    sequenceText = `${order.sequence_num} stop away.`;
  } else if (order.sequence_num > 1) {
    sequenceText = `${order.sequence_num} stops away.`;
  }
  const toggleModal = () => {
    setOpen(!open);
  };
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const foo = () => {
    let prodQuant = 0;
    let prodCost = 0;
    orderItems.map(item =>
      item.id
        ? ((prodQuant += item.quantity),
          (prodCost += item.cost * item.quantity))
        : ''
    );

    const st = [];
    if (!isCatalyst) {
      st.push({
        product: { name: 'SubTotal' },
        cost: currencyFormatter.format(prodCost)
      });
    }
    st.push({
      product: { name: 'Total number of items' },
      cost: prodQuant
    });
    orderItems.map(item => (!item.id ? st.push(item) : item.quantity));
    setDataValue(st);
  };
  useEffect(() => {
    foo();
  }, []);
  const driverRate = async () => {
    const data = {
      utf8: '✓',
      rating: `${rating}`,
      remark: remark,
      order_id: `${order.id}`,
      button: '',
      type: true
    };
    const response = await fetch('/driver_ratings', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status) {
        toggleModal();
      }
    });
    return response.json();
  };
  const userStatusChange = async (el, value) => {
    const status = value;
    const data = {
      utf8: '✓',
      order_id: `${order.id}`,
      remark: remark,
      button: '',
      type: true,
      user_status: `${status}`
    };
    const response = await fetch(
      `${hostUrl}/api/orders/customer-status-update`,
      {
        method: 'POST',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
      }
    ).then(res => {});
  };

  useEffect(() => {
    const cable = ActionCable.createConsumer();
    cable.subscriptions.create(
      {
        channel: `OrderChannel`,
        order_id: order.id
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: data => {
          setOrder({
            ...order,
            status: data.order.status,
            driver: {
              ...order.driver,
              first_name: data.order.driver_name
            },
            driver_id: data.order.driver_id,
            sequence_num: data.order.sequence_num,
            tour_arrival: data.order.tour_arrival
          });
          setOrderStatus(data.order.status);
          setEtaOrderState(data.order.tour_arrival);
          if (data.order.status === 'Delivered') {
            toggleModal();
          }
        }
      }
    );
  }, []);

  const changeRemarks = val => {
    setRemark(val);
  };
  const etaFunc = val => {
    setRemark(val);
  };
  return (
    <div>
      <Global
        styles={css`
          body {
            margin: 0;
          }
        `}
      />
      <Wrapper>
        <Card>
          <CardContent>
            <CardHeader container>
              <Grid item xs>
                <GridLogo container>
                  <Grid item>
                    <CardLogo>
                      <img src={storeLogo} alt="" width="100" height="100" />
                    </CardLogo>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5">{order.status}</Typography>
                    <div style={{ color: grey[600] }}>
                      <Typography variant="subtitle2">
                        Order ID:
                        {order.id}
                      </Typography>
                      <Typography variant="subtitle2">
                        Order Placed:{' '}
                        {dayjs(order.created_at).format('MMM DD, YYYY')}
                      </Typography>
                      {order.order_type === 'Shipping' ? (
                        <Typography variant="subtitle2">
                          Tracking Id:{' '}
                          { order.order_shipping[0].tracking_id}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {order.order_type === 'Shipping' ? (
                        <Typography variant="subtitle2">
                          Tracking Url:{' '}
                          <a href={order.order_shipping[0].tracking_url} target="_blank">
                            {order.order_shipping[0].tracking_url}
                          </a>
                        </Typography>
                      ) : (
                        ''
                      )}

                      {get(order, 'driver.first_name') && (
                        <Typography variant="subtitle2">
                          Driver: {`${get(order, 'driver.first_name', '')} `}
                        </Typography>
                      )}
                      <Typography variant="subtitle2">
                        {order.sequence_num && statusPlace ? sequenceText : ''}
                      </Typography>
                      {etaOrderState &&
                      ((order.status === 'Confirmed' && order.driver_id) ||
                        order.status === 'Out For Delivery') ? (
                        <Typography variant="subtitle2">
                          {`Approximate ETA: ${etaOrderState}min`}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                </GridLogo>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display="flex"
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <Chip color={lightBlue[600]} bgColor={lightBlue[50]}>
                      <Typography variant="h6">
                        {currencyFormatter.format(
                          order.total_cost - order.cash_balance_paid
                        )}
                      </Typography>
                    </Chip>
                  </Grid>
                  <Grid item>
                    <Typography
                      color={grey[600]}
                      margin=".2rem 0 0"
                      align="center"
                      variant="subtitle2"
                    >
                      {order.payment_type}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardHeader>
            <Typography color="red">
              {order.status === 'Pending'
                ? `Driver is unable to reach you. Please contact support at  ${order.location.phone}`
                : ''}
            </Typography>
            <Stepper
              statuses={orderStatuses}
              status={
                orderStatus === 'Confirmed' && order.driver_id
                  ? 'Picked up'
                  : orderStatus
              }
            />
            {!['Open', 'Delivered', 'Picked Up'].includes(order.status) &&
            order.order_type === 'Curbside' ? (
              <Grid item xs>
                <Button
                  style={{
                    width: '25%',
                    marginLeft: '30px',
                    backgroundColor: '#039be53d'
                  }}
                  variant="contained"
                  size="medium"
                  className="user-status"
                  value="On My Way"
                  onClick={e => userStatusChange(e, 'On My Way')}
                >
                  On My Way
                </Button>
                <Button
                  style={{
                    width: '25%',
                    marginLeft: '30px',
                    backgroundColor: '#039be53d'
                  }}
                  variant="contained"
                  size="medium"
                  className="user-status"
                  value="I'm Here"
                  onClick={e => userStatusChange(e, "I'm Here")}
                >
                  I'm Here
                </Button>
              </Grid>
            ) : (
              ''
            )}
            <Tabs
              onChange={handleTabChange}
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab />
            </Tabs>
            <TabPanel index={1} value={tabValue}>
              <TableWrapper>
                <Table data={dataValue} attributes={orderItemAttributes} />
              </TableWrapper>
            </TabPanel>
          </CardContent>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={toggleModal}
            closeAfterTransition
            BackdropProps={{
              timeout: 500
            }}
          >
            <div
              style={{
                backgroundColor: '#ffffff',
                padding: '20px',
                outline: 'none',
                maxWidth: '400px'
              }}
            >
              <div>
                <h2>Please rate your driver: {order && order.driver_name}</h2>
              </div>
              <div>
                <Rating
                  name="customized-empty"
                  defaultValue={0}
                  value={rating}
                  onChange={(event, newValue) => {
                    setRaiting(newValue);
                  }}
                  precision={0.5}
                  size="large"
                  style={{ fontSize: '3.8rem' }}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </div>
              <div>
                <TextareaAutosize
                  style={{
                    height: '142px',
                    margin: '0',
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '5px'
                  }}
                  value={remark}
                  aria-label="minimum height"
                  placeholder="Remarks about your driver (optional)"
                  onChange={e => changeRemarks(e.target.value)}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.margin}
                  onClick={driverRate}
                  disabled={rating < 0.5}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
        </Card>
      </Wrapper>
    </div>
  );
};

OrderStatusPage.propTypes = {
  orderProp: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    total_cost: PropTypes.string.isRequired,
    payment_type: PropTypes.string.isRequired
  }).isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  storeLogo: PropTypes.string.isRequired,
  orderItems: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired,
      quantity: PropTypes.number.isRequired,
      cost: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  statusPlace: PropTypes.string.isRequired
};

OrderStatusPage.defaultProps = {};

export default OrderStatusPage;
