// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UpdateInventory-module__content___lHkn0 {\n\tmargin-top: 16px;\n\n\theight: 350px;\n}\n\n.UpdateInventory-module__btnSubmit___1OLgP {\n\tmargin-top: 16px;\n}\n", "",{"version":3,"sources":["UpdateInventory.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;;CAEhB,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB","file":"UpdateInventory.module.css","sourcesContent":[".content {\n\tmargin-top: 16px;\n\n\theight: 350px;\n}\n\n.btnSubmit {\n\tmargin-top: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "UpdateInventory-module__content___lHkn0",
	"btnSubmit": "UpdateInventory-module__btnSubmit___1OLgP"
};
module.exports = exports;
