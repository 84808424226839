import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { get, pick, compact } from 'lodash';

import axios from 'axios';

import Alert from '../utils/Alert';

import { downloadCSV } from '../utils/csv';

import ProductTable from './ProductTable';
import getColumns from './ProductListColumns';
import { SnackbarProvider } from "notistack";
import toast, { Toaster } from 'react-hot-toast';

const ProductList = () => {
  const [datasource, setDatasource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [loadingRows, setLoadingRows] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [alert, setAlert] = useState({});
  const [brand, setBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [checkImage, noImage] = useState(false);
  const [selectedProductsGlobal, setSelectedProductsGlobal] = useState(null)
  const [closeUpdateButton, setCloseUpdateButton] = useState(false)

  const fetchBrands = async () => {
    const { data } = await axios.get('/api/brands/all');
    setBrandList(data);
  };
  const fetchData = async ({
    newPage = page,
    newPerPage = perPage,
    newSort = sort,
    newBrand = brand,
    disableLoading = false,
    checkImage
  } = {}) => {
    try {
      if (!disableLoading) setIsLoading(true);
      const { data } = await axios.get(`/products`, {
        params: {
          columns: compact(columns.map(i => i.selector)),
          page: Math.max(newPage, 1),
          per_page: newPerPage,
          sort: newSort,
          brand_id: newBrand,
          query: searchQuery || null,
          no_image: checkImage || false
        },
        headers: { Accept: 'application/json' }
      });
      setDatasource(data.products);
      setTotalCount(data.total_count);
      setMetaData(pick(data, ['can_update_product', 'can_update_catalogue', 'store_has_cova']));
      if (!disableLoading) setIsLoading(false);
    } catch (error) {
      setAlert({
        severity: 'error',
        message: get(error, 'message', 'Something went wrong')
      });
    }
  };

  const filterParameters = {
    disableLoading: true,
    checkImage: checkImage
  }

  const notify = () => toast.success('Product successfully added to Global Catalog!');

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    fetchData({ checkImage: checkImage});
  }, [page, perPage, sort, brand]);

  useEffect(() => {
    fetchData(filterParameters);
  }, [checkImage, searchQuery]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const conditionalStyles = [
    {
      when: row => loadingRows.includes(row.id),
      style: {
        opacity: 0.2,
        pointerEvents: 'none'
      }
    }
  ];

  async function updateActiveStatus({ productId, updateTo }) {
    try {
      setLoadingRows(state => [...state, productId]);
      await axios.post(`/change_active_status`, {
        product_id: productId,
        update_to: updateTo
      });
      await fetchData({ disableLoading: true });
      setLoadingRows(state => [...state.filter(i => i !== productId)]);
    } catch (error) {
      setAlert({
        severity: 'error',
        message: get(error, 'message', 'Something went wrong')
      });
    }
  }

  async function updateCatalogueState({ productId }) {
    try {
      setLoadingRows(state => [...state, productId]);
     const res = await axios.post(`/confirm_product`, {
        product_id: productId,
      });
      {res.status == 200?( notify(),closeButtonClickCatalog(productId)): null}
      await fetchData({ disableLoading: true });
      setLoadingRows(state => [...state.filter(i => i !== productId)]);
    } catch (error) {
      setAlert({
        severity: 'error',
        message: get(error, 'message', 'Something went wrong')
      });
    }
  }
const closeButtonClickCatalog = (productId)=>{
  selectedProductsGlobal.filter((i, index)=>{
       if (i== productId){
        selectedProductsGlobal.splice(index,1)
       }})
       if (selectedProductsGlobal.length > 0){
         setCloseUpdateButton(false)
       }else{setCloseUpdateButton(true)}
}
  const handleSort = async (column, direction) => {
    const newSort = `${direction === 'desc' ? '-' : ''}${get(
      column,
      'sortField',
      column.selector
    )}`;
    setSort(newSort);
  };

  const updateBrand = value => {
    setBrand(value || null);
  };

  const columns = getColumns({
    updateActiveStatus,
    updateCatalogueState,
    ...metaData
  });

  const exportCSV = () => {
    downloadCSV(datasource, columns);
  };

  return (
    <>
      <Alert clear={() => setAlert({})} {...alert} />
      <Toaster
  position="top-right"
  reverseOrder={false}
/>
      <SnackbarProvider>
      <ProductTable
        columns={columns}
        datasource={datasource}
        brandList={brandList}
        updateBrand={updateBrand}
        noImage={noImage}
        checkImage={checkImage}
        exportCSV={exportCSV}
        handleSort={handleSort}
        totalCount={totalCount}
        perPage={perPage}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        conditionalStyles={conditionalStyles}
        isLoading={isLoading}
        setSearchQuery={setSearchQuery}
        setSelectedProductsGlobal={setSelectedProductsGlobal}
        closeUpdateButton={closeUpdateButton}
        setCloseUpdateButton={setCloseUpdateButton}
      />
      </SnackbarProvider>
    </>
  );
};

export default ProductList;
