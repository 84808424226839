// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ImportData-module__content___3BHW6 {\n\tmargin-top: 16px;\n\n\theight: 350px;\n}\n\n.ImportData-module__btnSubmit____LsCb {\n\tmargin-top: 16px;\n}\n", "",{"version":3,"sources":["ImportData.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;;CAEhB,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB","file":"ImportData.module.css","sourcesContent":[".content {\n\tmargin-top: 16px;\n\n\theight: 350px;\n}\n\n.btnSubmit {\n\tmargin-top: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "ImportData-module__content___3BHW6",
	"btnSubmit": "ImportData-module__btnSubmit____LsCb"
};
module.exports = exports;
