import {
  ColumnModel,
  ColumnDataType,
  ColumnSortDirection
} from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('id', 'ID', 'Text', { visible: false, isKey: true }),
  createColumnModel('label', 'Label', {
    sortDirection: ColumnSortDirection.Ascending
  }),
  createColumnModel('nameComponent', 'Product Name'),
  createColumnModel('brandComponent', 'Brand'),
  createColumnModel('categoriesComponent', 'Categories'),
  createColumnModel('itemTypeComponent', 'Item Type'),
  createColumnModel('productTypeComponent', 'Is Concentrate'),  
  createColumnModel('unitPriceComponent', 'Unit Price'),
  createColumnModel('purchasePriceComponent', 'Purchase Price'),
  createColumnModel('netWeightComponent', 'Net Weight'),
  createColumnModel('shippedQuantityComponent', 'Shipped Quantity'),
  createColumnModel('receivedQuantityComponent', 'Received Quantity'),
  createColumnModel('packagingComponent', 'Product Packaging Date'),
  createColumnModel('expirationDateComponent', 'Product Expiration Date'),
  createColumnModel('mapProductComponent', 'Map Product'),
  createColumnModel('shipment_package_state', 'Status')
  //createColumnModel('actions')
];
