import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty, isNil } from 'lodash';
import axios from 'axios';

import Alert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Grid, Button } from '@material-ui/core';

import { DataGrid, ToolbarOptions } from 'tubular-react';
import columns from './DeliveryColumns';
import metrc from './apiClient';
import Wrapper from '../Wrapper';

import MapProductForm from './MapProductForm';
import DeliveryDetails from './DeliveryDetails';
import TableSelect from '../utils/TableSelect';
import InventoryMapProduct from './InventoryMapProduct';
import { linkStyle } from '../styles/helpers';
import ExpireDatePicker from '../utils/ExpireDatepicker';

import { addDays, formatISO } from 'date-fns';

const compareSort = (a, b) => {
  return a.id < b.id ? -1 : 1;
};

const licenseNumber = get(gon, 'store.metrc_info.license_number');

const fetchData = (deliveryId) => {
  return metrc.inventoryDetails({
    licenseNumber,
    transferId: deliveryId,
  });
};

const Delivery = ({ deliveryId }) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const [packages, setPackages] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [status, setStatus] = useState(null);
  const [productMapCova, setProductMapCova] = useState(null);

  const forceRefresh = () => setRefreshCount((state) => state + 1);

  const toggleCovaMapModal = (newProductMapCova) => {
    setProductMapCova(newProductMapCova);
  };

  const onUnMapClick = async (packageId) => {
    try {
      await metrc.unMapping({ packageId });
      forceFetchData();
      updateStatus({ severity: 'success', msg: 'Product unmapping is in progress, wait up to 5 minutes and then refresh the page' });
    } catch (err) {
      const msg = get(err, 'response.data.errors', [
        get(err, 'response.data.error.message', 'Request failed'),
      ]).join(' ');
      updateStatus({ severity: 'error', msg });
    }
  };

  const updateStatus = ({ msg, severity }) => {
    setStatus({ msg, severity });
    setTimeout(() => {
      setStatus(() => {
        return null;
      });
    }, 6000);
  };

  const getData = async (force = false) => {
    if (force || !packages || !deliveryDetails || isEmpty(categories)) {
      const { data } = await fetchData(deliveryId);

      setDeliveryDetails(data.info);
      setCategories(data.categories);

      return data;
    }
    return { list: packages, info: deliveryDetails, categories };
  };

  const updatePackages = (newPackages) => {
    setPackages(
      newPackages
        .map((item) => {
          return {
            ...item,
            nameComponent: (() => (
              <a
                href={`/products/provider_id/${item.product_id}`}
                style={linkStyle}
              >
                {item.name}
              </a>
            ))(),
            categoriesComponent: (
              <TableSelect
                options={categories}
                productId={item.product_id}
                productMetrcLabel={item.label}
                productQuantity={item.quantity}
                selectedValue={item.categories}
                valueAs="categories"
                label="Categories"
                inputType="autocomplete"
                handleUpdate={async (selected) => {
                  await metrc.updateCategory({
                    categories: selected.map(({ id }) => id),
                    packs: [item.id],
                  });
                  updateStatus({
                    severity: 'success',
                    msg: 'Categories updated',
                  });
                  forceFetchData();
                }}
                multiple
              />
            ),
            received_quantity: item.received_quantity || 0,
            unitPriceComponent: (() => (
              <TableSelect
                productId={item.product_id}
                productMetrcLabel={item.label}
                productQuantity={item.quantity}
                selectedValue={item.unit_price}
                valueAs="unit_price"
                label="Unit Price"
                inputType="number"
                inputProps={{ step: 0.01 }}
                onSubmit={(data) => {
                  if(!isNaN(data.success) && !data.success){
                    updateStatus({ severity: 'error', msg: data.message });
                  }else{
                    updateStatus({
                      severity: 'success',
                      msg: 'Unit price updated',
                    });
                    forceFetchData();
                  }
                }}
              />
            ))(),
            purchasePriceComponent: (() => (
              <TableSelect
                productId={item.product_id}
                productMetrcLabel={item.label}
                productQuantity={item.quantity}
                selectedValue={item.purchase_price}
                valueAs="purchase_price"
                label="Purchase Price"
                inputType="number"
                inputProps={{ step: 0.01 }}
                onSubmit={(data) => {
                  if(!isNaN(data.success) && !data.success){
                    updateStatus({ severity: 'error', msg: data.message });
                  }else {
                    updateStatus({
                      severity: 'success',
                      msg: 'Purchase price updated',
                    });
                    forceFetchData();
                  }
                }}
              />
            ))(),
            netWeightComponent: (() => (
              <TableSelect
                productId={item.product_id}
                productMetrcLabel={item.label}
                productQuantity={item.quantity}
                selectedValue={item.net_weight}
                valueAs="net_weight"
                label="Net Weight (grams)"
                inputType="number"
                inputProps={{ step: 0.01 }}
                onSubmit={() => {
                  updateStatus({
                    severity: 'success',
                    msg: 'Net Weight updated',
                  });
                  forceFetchData();
                }}
              />
            ))(),
            shippedQuantityComponent: (() => (
              <div>
                {item.shipped_quantity} {item.unit_of_measure_name}
              </div>
            ))(),
            receivedQuantityComponent: (() => (
              <>
                {item.unit_of_measure_name === 'Each' ||
                !isNil(item.received_quantity) ? (
                  item.received_quantity
                ) : (
                  <TableSelect
                    productId={item.product_id}
                    selectedValue={item.quantity_each}
                    inputType="number"
                    label="Quantity"
                    handleUpdate={async (val) => {
                      await metrc.addQuantity({
                        productId: item.id,
                        quantity: val,
                      });
                      updateStatus({
                        msg: 'Quantity updated',
                        severity: 'success',
                      });
                      forceFetchData();
                    }}
                  />
                )}
              </>
            ))(),
            itemTypeComponent: (
              <TableSelect
                options={[
                  { id: 'cannabis', name: 'Cannabis' },
                  { id: 'cannabis_product', name: 'Cannabis Product' },
                  { id: 'cannabis_accessories', name: 'Cannabis Accessories' },
                  { id: 'accessories', name: 'Accessories' }
                ]}
                inputType="autocomplete"
                productId={item.product_id}
                selectedValue={item.item_type || null}
                label="Item Type"
                valueAs="item_type"
                onSubmit={() => {
                  updateStatus({
                    msg: 'Item Type updated',
                    severity: 'success',
                  });
                  forceFetchData();
                }}
              />
            ),

            productTypeComponent: (
              <TableSelect
                options={[
                  { id: 'concentrate', name: 'Concentrate' },
                  { id: 'non_concentrate', name: 'Non Concentrate' }
                ]}
                inputType="autocomplete"
                productId={item.product_id}
                selectedValue={item.product_type || null}
                label="Is Concentrate"
                valueAs="product_type"
                onSubmit={() => {
                  updateStatus({
                    msg: 'Is Concentrate updated',
                    severity: 'success',
                  });
                  forceFetchData();
                }}
              />
            ),
            packagingComponent: (() => (
              <div>
                { moment(item.packaged_date).format('MM/DD/YYYY') }
              </div>
            ))(),
            brandComponent: (
              <TableSelect
                options={brands}
                productId={item.product_id}
                selectedValue={item.brand}
                inputType="autocomplete"
                label="Brand"
                valueAs="brand_id"
                onSubmit={() => {
                  updateStatus({ msg: 'Brand updated', severity: 'success' });
                  forceFetchData();
                }}
              />
            ),
            expirationDateComponent: (
              <ExpireDatePicker 
                onChange={ async (date) => {
                  try {
                    if(!isNaN(date.expireDate) && date.expireDate !== "Invalid Date") {
                      await metrc.addExpireDate({
                        productId: item.id,
                        productLabel: item.label,
                        expireDate: moment(date.expireDate).format('MM/DD/YYYY'),
                      });
                      updateStatus({
                        msg: 'Product Expire Date updated',
                        severity: 'success',
                      });
                      forceFetchData();
                    }else {
                      console.log("Added date is not valid")
                      updateStatus({ severity: 'error', 
                                     msg: "Added date is not valid" 
                                    });
                    }
                  }catch (err) {
                    console.log("Error ----", err)
                    console.log("Error message----", err.response.data)
                    const msg = get(err, 'response.data.errors', [
                      get(err, 'response.data.error.message', err.response.data),
                    ]).join(' ');
                    updateStatus({ severity: 'error', msg });
                  }
                }
              } expireDate={item.product_expiration_date} />
            ),

            mapProductComponent: (() => (
              <>
              { 
                item.is_mapped ? ( 
                  /* UnMap Product */
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onUnMapClick(item.id)}
                    style={{ marginRight: 6, marginBottom: 6 }}
                  >
                    UnMap Product
                  </Button>
                ) :

                (<MapProductForm product={item} showAlert={updateStatus} forceFetchData={forceFetchData} />)
               }
              </>
            ))()
             /* Map Cova Product */
            /*,           
            actions: (             
              <Button
                variant="outlined"
                color="primary"
                onClick={() => toggleCovaMapModal(item)}
                style={{ marginRight: 6, marginBottom: 6 }}
              >
                Map Cova Product
              </Button>
            ),*/
          };
        })
        .sort(compareSort)
    );
  };

  const toolbarOptions = new ToolbarOptions({
    searchText: false,
    customItems: [],
  });

  async function updateData(force = false) {
    const data = await getData(force);

    updatePackages(data.list);
  }

  const forceFetchData = async () => {
    await updateData(true);
    //forceRefresh();
  };

  useEffect(() => {
    updateData();
  }, [brands, refreshCount, categories]);

  useEffect(() => {
    updateData();
  }, []);
  
  useEffect(() => {
    async function fetchBrands() {
      const { data } = await axios.get('/api/brands/all');

      setBrands(data);
    }
    fetchBrands();
  }, []);

  const handleGoBack = () => {
    window.location.href = '/metrc/intake';
  };

  const onSubmitMapProduct = async (payload) => {
    try {
      await axios.post(`/products/mapping`, payload);
      await metrc.processCovaMapping();
      forceFetchData();
      updateStatus({ severity: 'success', msg: 'Product mapped successfuly' });
      setProductMapCova(null);
    } catch (err) {
      const msg = get(err, 'response.data.errors', [
        get(err, 'response.data.error.message', 'Request failed'),
      ]).join(' ');
      updateStatus({ severity: 'error', msg });
    }
  };

  return (
    <Wrapper>
      <div>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              onClick={handleGoBack}
              style={{ marginBottom: 16 }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            {status && (
              <Alert
                style={{ position: 'fixed', right: 30, top: 30, zIndex: 9999 }}
                severity={status.severity}
              >
                {status.msg}
              </Alert>
            )}
          </Grid>
        </Grid>
        <DeliveryDetails info={deliveryDetails} style={{ margin: '2rem 0' }} />
        <DataGrid
          gridName="delivery"
          columns={columns}
          dataSource={packages}
          toolbarOptions={toolbarOptions}
        />
        {!!productMapCova && (
          <InventoryMapProduct
            product={productMapCova}
            onClose={() => toggleCovaMapModal(null)}
            onSubmit={onSubmitMapProduct}
          />
        )}
      </div>
    </Wrapper>
  );
};

Delivery.propTypes = {
  deliveryId: PropTypes.number.isRequired,
};

export default Delivery;
