/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import { capitalize, invoke, get, has } from 'lodash';

import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

import { Table as TableStyled } from '../OrderStatusPageStyled';

const processData = (raw, havePaging, { page, rowsPerPage }) => {
  if (havePaging) {
    return raw.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }
  return raw;
};

function Table({
  data: rawData,
  attributes,
  tableCellProps,
  tableRowProps,
  havePaging,
  tablePaginationProps
}) {
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const data = processData(rawData, havePaging, { page, rowsPerPage });
  return (
    <>
      <TableStyled stickyHeader>
        <TableHead>
          <TableRow>
            {attributes.map(attr => (
              <TableCell
                {...tableCellProps}
                key={attr.label}
                width={attr.width}
                align={attr.align || 'left'}
              >
                {capitalize(attr.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ? data.map(item => (
                <TableRow key={item.id} {...tableRowProps[item.id]}>
                  {attributes.map(attr => (
                    <TableCell
                      key={`${item.id}_${attr.label}`}
                      width={attr.width}
                      align={attr.align || 'left'}
                      {...tableCellProps}
                    >
                      {has(attr, 'value')
                        ? invoke(attr, 'value', item)
                        : get(item, attr.key, attr.default || '-') ||
                          attr.default}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : null}
        </TableBody>
      </TableStyled>
      {havePaging ? (
        <TablePagination
          {...tablePaginationProps}
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={rowsPerPage}
          page={page}
          count={rawData.length}
          onChangePage={(e, newPage) => setPage(newPage)}
        />
      ) : null}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      value: PropTypes.func,
      key: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  tableCellProps: PropTypes.object,
  tableRowProps: PropTypes.object,
  tablePaginationProps: PropTypes.object,

  havePaging: PropTypes.bool
};

Table.defaultProps = {
  tableCellProps: {},
  tableRowProps: {},
  tablePaginationProps: {},
  havePaging: false
};

export default memo(Table);
