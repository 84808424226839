import React from 'react';

import {isEmpty} from 'lodash';

import {Button} from '@material-ui/core';

const currencyFormatter = num =>
    Number.isFinite(num)
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(num)
        : 0;

const numberCommaFormatter = num =>
    Number.isFinite(num) ? new Intl.NumberFormat('en-US').format(num) : 0;

export default ({
  updateActiveStatus,
  updateCatalogueState,
  store_has_cova: storeHasCova,
  can_update_product: canUpdateProduct,
  can_update_catalogue: canUpdateCatalogue
} = {}) => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    cell: row => <a href={`/products/${row.id}`}>{row.name}</a>
  },
  {
    name: 'Brand',
    selector: 'brand_name',
    sortable: true
  },
  {
    name: 'Categories',
    selector: 'categories_name',
    sortable: true
  },
  {
    name: 'Total Quantity',
    selector: 'quantity',
    sortField: 'total_quantity',
    sortable: true,
    cell: row => <>{numberCommaFormatter(row.quantity)}</>
  },
  {
    name: 'Purchase Price',
    selector: 'purchase_price',
    sortable: true,
    cell: row => <>{numberCommaFormatter(parseFloat(row.purchase_price))}</>
  },
  {
    name: 'Net Weight',
    selector: 'net_weight',
    sortable: true,
    cell: row => <>{numberCommaFormatter(row.net_weight)}</>
  },
  {
    name: 'Sale Price',
    selector: 'unit_price',
    sortable: true
  },
  {
    name: 'Active',
    selector:'active',
    sortable: true,
    omit: !(!storeHasCova && canUpdateProduct),
    cell: row => (
      <Button
        style={{
          width: 42,
          height: 42,
          borderRadius: 50,
          minWidth: 0,
          padding: 0,
          margin: 10
        }}
        color={row.active ? 'primary' : 'secondary'}
        variant="contained"
        size="small"
        onClick={() =>
          updateActiveStatus({ productId: row.id, updateTo: !row.active })
        }
      >
        {row.active ? 'Yes' : 'No'}
      </Button>
    )
  },
  {
    name: 'Catalog Source',
    selector: 'product_catalogue_id',
    sortable: true,
      cell: row => (
        row.product_catalogue_id == null ?
            canUpdateCatalogue ?
        <Button
            style={{
                backgroundColor: '#18a689',
                borderColor: '#18a689',
                color:  '#FFFFFF',
                padding: '1px 5px',
                fontSize: '11px',
                textTransform: 'capitalize',

            }}
            variant="contained"
            size="small"
            onClick={() =>
                updateCatalogueState({ productId: row.id })
            }>
            Add to Global Catalog
        </Button>
                :  <span className="badge badge-success">
                        Local Catalog
                      </span>

                : <span className="badge badge-success">
                        Global Catalog
                      </span>
    )
  },
];
