import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ReactExport from 'react-data-export';
import DataTable, { createTheme } from 'react-data-table-component';

import { LinearProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Print from './Print'
import AutocompleteForm from '../utils/AutocompleteForm';
import { useSnackbar } from 'notistack';

import debounce from '../utils/debounce';
import { UpdateMultipleProducts } from './UpdateMultipleProducts';
import Checkbox from '@material-ui/core/Checkbox';

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import {CopyToClipboard} from 'react-copy-to-clipboard';

const LinearProgressUI = () => {
  return (
    <div style={{ width: '100%', height: 600 }}>
      <LinearProgress />
    </div>
  );
};




const useStyles = makeStyles({
  header: {
    flex: '1 0 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    position: 'absolute', 
    right: 0, 
    top: 50,
    '@media print': {
      display: 'none',
    }
  }
});

createTheme('solarized', {
 
  context: {
    background: 'transparent',
    text: '#212121',
  },
});

const ProductTable = ({
  datasource,
  columns,
  isLoading,
  brandList,
  noImage,
  checkImage,
  updateBrand,
  setSearchQuery,
  totalCount,
  perPage,
  handlePerRowsChange,
  handlePageChange,
  conditionalStyles,
  exportCSV,
  showHeader,
  handleSort,
  setSelectedProductsGlobal,
  closeUpdateButton,
  setCloseUpdateButton
}) => {
  const componentRef = useRef();
  const classes = useStyles();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

 

  // export Excel
  const [cool, setCool]= useState([])
  const [daat, setDaat]= useState([])

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const multiDataSet = [{
    columns: cool,
    data: daat
  }]

  

  const dataOfExcel=()=>{

    const row = datasource.map(r=>{
      return [{value: r.display_name && r.display_name !== null?r.display_name: '', style: {font: {sz: "12"}}},
              {value: r.brand_name && r.brand_name !== null?r.brand_name:'', style: {font: {sz: "12"}}},
              {value: r.categories_name && r.categories_name !== null? r.categories_name: '', style: {font: {sz: "12"}}},
              {value: r.total_quantity && r.total_quantity !== null?r.total_quantity: '', style: {font: {sz: "12"}}},
              {value: r.purchase_price && r.purchase_price!== null?r.purchase_price: '' , style: {font: {sz: "12"}}},
              {value: r.net_weight && r.net_weight !== null ?r.net_weight: '', style: {font: {sz: "12"}}},
              {value: r.unit_price && r.unit_price !== null?r.unit_price: '', style: {font: {sz: "12"}}}]
       })
       setDaat(row)


       const col = columns.map(c=>{
        return {title: c.name, width: {wch: 23}}
         })
         setCool(col)
         
       }
    
useEffect(()=>{
  dataOfExcel()
},[columns])

  //PDF generate
  const generatePDF = () => {
    const doc = new jsPDF()
  const col = columns.map((i)=>{
    let ar = []
    ar.push(i.name)
    return ar
  })

  const row = datasource.map((i)=>{
    let ar = []
   ar.push(
     i.display_name,
     i.brand_name,
     i.categories_name,
     i.total_quantity,
     i.purchase_price,
     i.net_weight,
     i.unit_price,
     i.product_catalogue_id,)
    return ar
  })
  doc.autoTable({
    head: [col.slice(0, col.length-2)],
    body: row
  })
  doc.save('PDF.pdf')
  }
  //Copy
  
  const [copyString, setcopyString] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  

const copy =()=>{
  let rowArr = []
  let columArr = []
  if (columns && datasource){
    columns.map((j)=>{
      columArr.push(j.name)         
       
      datasource.map((i)=>{ 
         if (!rowArr[datasource.length-1])   
         rowArr.push([
          i.display_name,
          i.brand_name,
          i.categories_name,
          i.total_quantity,
          i.purchase_price,
          i.net_weight,
          i.unit_price,
          i.product_catalogue_id])
        })
        })       
      }else{
            return
      }       
      for (let i=0;i<rowArr.length; i++){
        rowArr[i] = rowArr[i].join('     ') 
      }
      setcopyString(columArr.join('            ') + rowArr)
    }
      useEffect(()=>{
                copy()
      }, [columns, datasource])
         
//  /////////////////////////PRINT
      const  printDiv=()=> {
        window.frames["print_frame"].document.body.innerHTML = document.getElementById("printableTable").innerHTML;
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
      }
       
    
  // ///////////////////Update Multiple Products
const [isClickedMultiple, setIsClickedMultiple] = useState(false)
const [isClickedCheckbox, setIsClickedCheckbox] = useState(false)
const [state, setState] = React.useState({ toggledClearRows: false, selectedRows: false });
const [brandListSearch, setBrandListSearch] = useState([]);
const [prodTypes, setProdTypes] = useState(null);
const [categoriesTypes, setCategoriesTypes] = useState(null);
const [selectedProducts, setSelectedProducts] = useState(null)




const fetchBrands = async () => {
  const term = ""
  const  res  = await axios.get(`/brand_search?term=${term}`);
  setBrandListSearch(res.data);
};

const getProdTypesCategories = async () => {
  
  const  res  = await axios.get(`/get_prod_types_and_categories`);
  setProdTypes(res.data.product_types);
  setCategoriesTypes(res.data.categori_tipes)
};

const showCheckboxes = ()=>{
  setIsClickedMultiple(true)
  setCloseUpdateButton(false)
  setState({...state, selectedRows: true})
}

const hideCheckboxes= ()=> {
  setIsClickedMultiple(false)
  setIsClickedCheckbox(false)
  handleClearRows()
}
const handleChange = (state) => {
  setSelectedProducts(state.selectedRows.map(i=>{
   return      i.id.toString()
        }))
     setSelectedProductsGlobal(state.selectedRows.map(i=>{
      return      i.id.toString()
           }))

  state.selectedRows.length > 0?(
    setIsClickedCheckbox(true)) : (
      setIsClickedCheckbox(false))
      
    };
    const handleClearRows = () => {
      setState({ toggledClearRows: !state.toggledClearRows})
      setIsClickedCheckbox(false)
      
    }

    useEffect(()=>{
      fetchBrands()
      getProdTypesCategories()
    },[])
    
  return (
    <div id="printTable" ref={componentRef} style={{ marginTop: 16 }}>
      <DataTable
        title={
          showHeader ? (
            <div style={{ padding: 16 }}>
              <AutocompleteForm  
                inputType="autocomplete"
                inputLabel="Filter brand"
                options={brandList}
                onSubmit={updateBrand}
                onChange={updateBrand}
                autoFocus={false}
              />
              <label className="noImageLabel"  htmlFor="no-image" style={{ fontSize: 15, fontWeight: "normal", paddingRight: 7}}>Products without images</label>
              <input type="checkbox"  id="no-image" value={checkImage} onChange={()=>noImage(!checkImage)} />
            
            <UpdateMultipleProducts 
              showCheckboxes ={showCheckboxes}
              isClickedMultiple = {isClickedMultiple}
              hideCheckboxes = {hideCheckboxes}
              isClickedCheckbox = {isClickedCheckbox}
              selectedProducts = {selectedProducts}
              brandListSearch={brandListSearch}
              prodTypes={prodTypes}
              categoriesTypes={categoriesTypes}
              closeUpdateButton={closeUpdateButton}
              />
            </div>
          ) : null
        }
        actions={
          showHeader ? (
            <div className={classes.header}>
              <AutocompleteForm
                inputLabel="Search"
                autoFocus={false}
                onChange={debounce(query => {
                  setSearchQuery(query);
                }, 300)}
              />
                <div>               
               <CopyToClipboard text={copyString}>
                      <Button onClick={() => enqueueSnackbar("Copy to Clipboard ")} >Copy</Button>
               </CopyToClipboard>               
               </div>
              <Button onClick={exportCSV}>CSV</Button>
              <div>
                <ExcelFile filename="Excel" element={<Button>Excel</Button>}>
                    <ExcelSheet dataSet={multiDataSet} name="Organization"/>
                </ExcelFile>
            </div>
              <Button onClick={generatePDF} >PDF</Button>             
               <div>  
              <Button className="Button Button--outline" onClick={printDiv}>Print</Button>
                  <div style={{ display: "none" }} id="printableTable">
                     <Print columns={columns} datasource={datasource}/>
                </div>
                   <iframe name="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
                </div>
            </div>
          ) : null
        }
        columns={columns}
        data={datasource}
        onSelectedRowsChange={handleChange}          
        selectableRows = {state.selectedRows}
        clearSelectedRows={state.toggledClearRows}
        selectableRowsComponent={Checkbox}
        selectableRowsNoSelectAll = {true}
        selectableRowDisabled={(row) =>row.product_catalogue_id > 0}
        options={{
          actionsColumnIndex: -1
        }}
        noContextMenu = {true}
        progressPending={isLoading}
        progressComponent={<LinearProgressUI />}
        pagination
        paginationServer
        paginationServerOptions={{persistSelectedOnPageChange: true, persistSelectedOnSort: true }}
        paginationTotalRows={totalCount}
        paginationPerPage={perPage}
        paginationDefaultPage={1}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalStyles}
        striped
        sortServer
        onSort={handleSort}
      />
    </div>
  );
};


ProductTable.propTypes = {
  prodTypes: PropTypes.arrayOf(PropTypes.object),
  categoriesTypes: PropTypes.arrayOf(PropTypes.object),
  datasource: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  brandList: PropTypes.arrayOf(PropTypes.object),
  showHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  perPage: PropTypes.number,
  conditionalStyles: PropTypes.arrayOf(PropTypes.object),
  handleSort: PropTypes.func,
  handlePerRowsChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  updateBrand: PropTypes.func,
  noImage: PropTypes.func,
  checkImage: PropTypes.bool,
  setSearchQuery: PropTypes.func,
  exportCSV: PropTypes.func
};

ProductTable.defaultProps = {
  prodTypes: [],
  categoriesTypes: [],
  datasource: [],
  columns: [],
  brandList: [],
  showHeader: true,
  isLoading: true,
  totalCount: 0,
  perPage: 0,
  conditionalStyles: [],
  handleSort: () => {},
  handlePerRowsChange: () => {},
  handlePageChange: () => {},
  updateBrand: () => {},
  noImage: () => {},
  setSearchQuery: () => {},
  exportCSV: () => {}
};

export default ProductTable;