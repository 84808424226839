import React from 'react';
import PropTypes from 'prop-types';

import { merge } from 'lodash';

import { capitalize, invoke } from 'lodash';
import { formatRelative } from 'date-fns';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const getAlerts = ({ success, attempt, created_at: createdAt }) => {
  if (success)
    return [
      {
        title: 'Successfully reported to METRC',
        severity: 'success',
        msg: () =>
          createdAt
            ? capitalize(formatRelative(new Date(createdAt), new Date()))
            : null,
      },
    ];

  return [
    {
      title: 'Reporting to METRC failed',
      severity: 'error',
      msg: () => (
        <>
          {createdAt && (
            <>
              Last Attempted <br />
              {capitalize(formatRelative(new Date(createdAt), new Date()))}
            </>
          )}
        </>
      ),
    },
    {
      title: `Trying to sync to METRC`,
      severity: 'info',
    },
  ];
};

const OrderLogStatus = ({ log }) => {
  if (!log) log = { success: false };

  const alerts = getAlerts(log);

  return (
    <div>
      {alerts.map((alert) => (
        <Alert style={{ marginBottom: 16 }} severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          <div>{invoke(alert, 'msg')}</div>
        </Alert>
      ))}
    </div>
  );
};

OrderLogStatus.propTypes = {
  log: PropTypes.shape({
    order_id: PropTypes.number,
    success: PropTypes.bool,
    created_at: PropTypes.string,
  }),
};

OrderLogStatus.propTypes = {
  log: {},
};

export default OrderLogStatus;
