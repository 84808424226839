import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';

import metrc from './apiClient';

const styles = {
  form: {
    width: 160,
    marginLeft: 16
  }
};

function UpdateProductExpierDate({quantity, packageId, productLabel, expireDate, expireDateAs }) {
  //const [enteredExpierDate, setEnteredExpierDate] = useState('');
  const handleOnChange = async date => {
    if(!isNaN(date.expireDate) && date.expireDate !== "Invalid Date") {
      await metrc.addExpireDate({
        productId: packageId,
        productLabel: productLabel,
        expireDate: date.expireDate,
      });
      location.reload();
    }else {
      alert("Added date is not valid")
    }
    return false;
  };

  const [selectedDate, setSelectedDate] = React.useState();
  const [wasOpened, setWasOpened] = React.useState();

  const handleDateChange = (date) => {
    if (wasOpened)
    {
      handleOnChange({ [expireDateAs]: date })
    }
  };

  const handleOnOpen = () => {
    setWasOpened(true);
  };

  const handleOnClose = () => {
    setWasOpened(false);
  };

  const addNewDate = (event) => {
    handleOnChange({ [expireDateAs]: new Date(event.target.value) })
  }

  return (
    <div style={styles.form} >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              format="MM-dd-yyyy"
              variant="inline"
              value = {expireDate ? expireDate : moment()}
              clearable
              disablePast
              onChange = { handleDateChange }
              onBlur= { addNewDate }
              //onKeyDown = { (event) => { if (event.keyCode === 13) { addNewDate(event) } } }
              onOpen= { handleOnOpen }
              onClose= { handleOnClose }
              disabled = {quantity > 0 ? false : true}
            />
      </MuiPickersUtilsProvider>
    </div>
  );
}

UpdateProductExpierDate.propTypes = {
  expireDate: PropTypes.instanceOf(Date).isRequired,
  expireDateAs: PropTypes.string,
  onChange: PropTypes.func
};

UpdateProductExpierDate.defaultProps = {
  onChange: () => {},
  expireDateAs: 'expireDate',
  startProps: {},
};

export default UpdateProductExpierDate;
