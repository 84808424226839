import React from 'react';
import ReactDom from 'react-dom';
import {
  CompareOperators,
  GridRequest,
  GridResponse,
  LocalStorage,
  NullStorage,
  sortColumnArray,
} from 'tubular-common';
import {
  getLocalDataSource,
  getRemoteDataSource,
  tbId,
} from 'tubular-react-common/dist/helpers';

const createTbOptions = (tubularOptions) => {
  const temp = tubularOptions || {};
  return {
    callbacks: temp.callbacks || {
      onError: () => {
        return;
      },
    },
    componentName: temp.componentName || tbId(),
    deps: temp.deps || null,
    pagination: temp.pagination || {
      itemsPerPage: 10,
      page: 0,
    },
    searchText: temp.searchText || '',
    storage: (temp.componentName && temp.storage) || new NullStorage(),
  };
};
const useTubular = (initColumns, source, tubularOptions) => {
  const tbOptions = createTbOptions(tubularOptions);

  const {
    componentName,
    pagination,
    callbacks,
    storage,
    deps,
    searchText,
  } = tbOptions;

  const initStorage = storage || new NullStorage();
  if (initStorage instanceof LocalStorage) {
    initStorage.setGridName(componentName);
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const [getColumns, setColumns] = React.useState(initColumns);
  const [isStorageLoaded, setIsStorageLoaded] = React.useState(false);
  const [getActiveColumn, setActiveColumn] = React.useState(null);
  const [getItemsPerPage, setItemsPerPage] = React.useState(
    pagination.itemsPerPage || 10
  );
  const [getStorage] = React.useState(initStorage);
  const [getPage, setPage] = React.useState(pagination.page || 0);
  const [getSearchText, setSearchText] = React.useState(searchText || '');
  const [getError, setError] = React.useState(null);
  const getAllRecords =
    source instanceof Array
      ? getLocalDataSource(source)
      : getRemoteDataSource(source);

  const [getState, setState] = React.useState({
    aggregate: null,
    data: [],
    filteredRecordCount: 0,
    totalRecordCount: 0,
  });

  const api = {
    exportTo: async (allRows, exportFunc) => {
      if (getState.filteredRecordCount === 0) {
        return;
      }

      const payload = allRows
        ? (
            await getAllRecords(
              new GridRequest(getColumns, -1, 0, getSearchText)
            )
          ).payload
        : getState.data;

      exportFunc(payload, getColumns);
    },
    goToPage: (page) => {
      if (getPage !== page) {
        setPage(page);
      }
    },
    handleFilterChange: (filterText, filterOperator, filterArgument) => {
      setActiveColumn({
        ...getActiveColumn,
        filterText,
        filterOperator,
        filterArgument,
      });
    },
    processRequest: async () => {
      setIsLoading(true);

      try {
        const request = new GridRequest(
          getColumns,
          getItemsPerPage,
          getPage,
          getSearchText
        );
        const response = await getAllRecords(request);

        console.log('processRequest', response);
        const maxPage = Math.ceil(response.totalRecordCount / getItemsPerPage);
        let currentPage =
          response.currentPage > maxPage ? maxPage : response.currentPage;
        currentPage = currentPage === 0 ? 0 : currentPage - 1;

        // TODO: Check this won't case an issue
        ReactDom.unstable_batchedUpdates(() => {
          getStorage.setPage(currentPage);
          getStorage.setColumns(getColumns);
          getStorage.setTextSearch(getSearchText);

          setState({
            aggregate: response.aggregationPayload,
            data: response.payload,
            filteredRecordCount: response.filteredRecordCount || 0,
            totalRecordCount: response.totalRecordCount || 0,
          });

          setIsLoading(false);
          setError(null);
          setPage(currentPage);
        });
      } catch (err) {
        if (callbacks.onError) {
          callbacks.onError(err);
        }

        setIsLoading(false);
        setError(err);
      }
    },
    setActiveColumn,
    setColumns,
    setFilter: (filterText, filterOperator, filterArgument) => {
      const columns = [...getColumns];
      const column = columns.find((c) => c.name === getActiveColumn.name);
      if (!column) {
        return;
      }
      column.filterText = filterText;
      column.filterOperator = filterOperator;
      column.filterArgument = filterArgument;

      setColumns([...columns]);
    },
    sortColumn: (property, multiSort) => {
      const columns = sortColumnArray(property, [...getColumns], multiSort);

      setColumns(columns);
    },
    updateItemPerPage: (itemsPerPage) => {
      if (getItemsPerPage !== itemsPerPage) {
        setItemsPerPage(itemsPerPage);
      }
    },
    updateSearchText: (value) => {
      if (getSearchText !== value) {
        setSearchText(value);
      }
    },
  };

  let dependencies = [
    getColumns,
    getPage,
    getSearchText,
    getItemsPerPage,
    source,
  ];

  if (deps) {
    dependencies = dependencies.concat(deps);
  }

  const initGrid = () => {
    if (getStorage.getPage()) {
      setPage(getStorage.getPage());
    }

    if (getStorage.getTextSearch()) {
      setSearchText(getStorage.getTextSearch());
    }

    const storedColumns = getStorage.getColumns();

    if (storedColumns) {
      const columns = [...getColumns];

      storedColumns.forEach((column) => {
        const currentColumn = columns.find((col) => col.name === column.name);

        if (!currentColumn) {
          return;
        }

        currentColumn.visible = column.visible;

        if (
          currentColumn.filterText !== null &&
          column.filterOperator !== CompareOperators.None
        ) {
          return;
        }

        currentColumn.filterText = column.filterText;
        currentColumn.filterOperator = column.filterOperator;
        currentColumn.filterArgument = column.filterArgument;
      });

      setColumns(columns);
    }

    setIsStorageLoaded(true);
  };

  if (!isStorageLoaded) {
    initGrid();
  }

  React.useEffect(() => {
    if (!isLoading) {
      api.processRequest();
    }
  }, dependencies);

  React.useEffect(() => {
    setColumns(initColumns);
  }, [initColumns]);

  console.log('state', getState.filteredRecordCount);

  const state = {
    ...getState,
    activeColumn: getActiveColumn,
    columns: getColumns,
    error: getError,
    initialized: isStorageLoaded,
    isLoading,
    itemsPerPage: getItemsPerPage,
    page: getPage,
    searchText: getSearchText,
    storage: getStorage,
  };

  return { state, api };
};

export default useTubular;
