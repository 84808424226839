import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { capitalize } from 'lodash';
import { format } from 'date-fns';

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from 'recharts';

const timespanOptions = ['daily', 'monthly', 'yearly'];

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const dateFormat = (timespan) => {
  switch (timespan) {
    case 'daily':
      return 'MMMM do';
    case 'monthly':
      return 'MMMM';
    case 'yearly':
      return 'u';
  }
};

const processData = (data, { timespan }) => {
  return data.map((item) => ({
    ...item,
    value: item.value || 0,
    valueFormat: currencyFormatter.format(item.value),
    label: format(new Date(item.label), dateFormat(timespan)),
  }));
};

const yAxisFormatter = (value) => {
  return currencyFormatter.format(value);
};

const LineLabel = ({ x, y, stroke, value }) => {
  return (
    value && (
      <text x={x} y={y} dy={-5} fill={stroke} fontSize={10} textAnchor="middle">
        {currencyFormatter.format(value)}
      </text>
    )
  );
};

const SalesChart = (props) => {
  const [data, setData] = useState(null);
  const [timespan, setTimespan] = useState('daily');

  const changeTimespan = (e) => {
    setTimespan(e.target.value);
  };

  const fetchData = async () => {
    const result = await axios.get(`/sales/timespan/${timespan}?range=4`);

    const data = processData(result.data, { timespan });

    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, [timespan]);

  return (
    <div className="ibox">
      <div className="ibox-title">
        <div className="pull-left">
          <h5>
            Total sales <small>by timespan</small>
          </h5>
        </div>
        <div className="pull-right">
          <select name="timespan" id="timespan" onChange={changeTimespan}>
            {timespanOptions.map((opt) => (
              <option value={opt}>{capitalize(opt)}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="ibox-content">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 10, right: 60, bottom: 10, left: 60 }}
        >
          <XAxis dataKey="label" />
          <YAxis dataKey="value" tickFormatter={yAxisFormatter} />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Tooltip></Tooltip>
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            label={<LineLabel />}
          />
        </LineChart>
      </div>
    </div>
  );
};

export default SalesChart;
