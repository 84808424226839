import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { get, invoke, isEmpty } from 'lodash';

import {
  Modal,
  Typography,
  Fade,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  IconButton,
  Button,
  CardActions,
  FormControl,
  TextField,
  Backdrop,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { Autocomplete } from '@material-ui/lab';

import { debounce } from '../utils';

const COVA = 'cova';

const useStyles = makeStyles({
  modalInner: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,

    margin: '0 auto',
    maxWidth: 460,

    transform: 'translateY(-50%)',
    overflowY: 'auto',
  },
  modalCard: {
    flex: '1 0 100%',
    margin: '0 auto',
  },
});

function InventoryMapProduct({ product, onClose, onSubmit, nameAs }) {
  const [payload, setPayload] = useState({
    provider_id: product.product_id,
  });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const updateProducts = async ({ query, filter }) => {
    setLoading(true);
    const { data } = await axios.get(`/products/search`, {
      params: {
        query,
        ...filter,
      },
    });
    setProducts(data);
    setLoading(false);
  };

  const handleInputChange = ({ value }) => {
    if (!value) return;

    updateProducts({ query: value, filter: { provider: COVA } });
  };

  const handleValueChange = (_e, obj) => {
    setPayload((state) => ({
      ...state,
      to_product_id: isEmpty(obj) ? null : obj.value,
    }));
  };

  const attributes = [
    {
      label: 'Product',
      editable: false,
      func: (prop) => <Typography>{prop.product[nameAs]}</Typography>,
    },
  ];

  attributes.push({
    label: 'Cova Product',
    editable: true,
    func: () => (
      <Autocomplete
        options={products}
        id="select-cova-product"
        getOptionLabel={(option) => option.label}
        onChange={handleValueChange}
        clearonblur={false}
        onInputChange={debounce(
          (e, value) => handleInputChange({ e, value }),
          500
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select Cova Product" />
        )}
      />
    ),
  });

  const formDisabled = !attributes.some((item) => item.editable);

  const submitForm = () => {
    if (formDisabled) return;
    onSubmit(payload);
  };

  return (
    <Modal
      aria-labelledby="map-product-title"
      aria-describedby="map-product-desc"
      open={!!product}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modalRoot}
    >
      <Fade in={!!product}>
        <div className={classes.modalInner}>
          <Card className={classes.modalCard}>
            {loading && <LinearProgress />}
            <CardContent>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    id="map-product-title"
                  >
                    Map Product
                  </Typography>
                  <Typography variant="h5" component="h2">
                    Product #{get(product, 'product_id')}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <div style={{ marginTop: 32 }}>
                <form onSubmit={submitForm} name="map-product">
                  {attributes.map((attr) => (
                    <Grid
                      container
                      style={{ marginTop: 8, lineHeight: '24px' }}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={5} style={{ lineHeight: '30px' }}>
                        <Typography align="right">{attr.label}:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{ paddingLeft: 8, lineHeight: '30px' }}
                      >
                        {invoke(attr, 'func', { product } || {})}
                      </Grid>
                    </Grid>
                  ))}
                </form>
              </div>
            </CardContent>
            <CardActions style={{ marginTop: 16 }}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  form="map-product"
                  disabled={formDisabled}
                >
                  Map Product
                </Button>
              </FormControl>
            </CardActions>
          </Card>
        </div>
      </Fade>
    </Modal>
  );
}

InventoryMapProduct.defaultProps = {
  nameAs: 'name',
};

InventoryMapProduct.propTypes = {
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  nameAs: PropTypes.string,
};

export default InventoryMapProduct;
