import React from 'react';
import {
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import { hot } from 'react-hot-loader';
import { get, random } from 'lodash';

function legendFormatter(_v, { payload: { name, value } }) {
  return (
    <span>
      {name}:<span> </span>
      {value}
    </span>
  );
}

const colors = [
  '#003f5c',
  '#444e86',
  '#955196',
  '#dd5182',
  '#ff6e54',
  '#ffa600',
];

const getColor = (index) => {
  if (index > colors.length - 1) return colors[random(colors.length)];

  return colors[index];
};

function PieChart({ data, dataKey, nameKey }) {
  const chartData = data.map((item, index) => ({
    ...item,
    color: getColor(index),
  }));

  return (
    <div>
      <ResponsiveContainer width="100%" height={200}>
        <RechartsPieChart
          margin={{
            top: 50,
            left: 0,
          }}
        >
          <Pie
            data={chartData}
            dataKey={dataKey}
            nameKey={nameKey}
            cx={150}
            cy={50}
            outerRadius={80}
            label
          >
            {chartData.map((entry) => (
              <Cell
                key={entry.origin}
                fill={get(entry, 'color')}
                stroke={get(entry, 'color')}
                color={get(entry, 'color')}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            verticalAlign="top"
            layout="vertical"
            align="right"
            formatter={legendFormatter}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default hot(module)(PieChart);
