import React from 'react';

import PropTypes from 'prop-types';

import { useTheme, ThemeProvider } from '@material-ui/core/styles';

const Wrapper = ({ children }) => {
  const theme = useTheme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;

