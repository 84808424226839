// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Print-module__custom___2D5ye {\n    font-family: Arial, Helvetica, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  .Print-module__page___1Jvam {\n    size: A4;\n    margin: 0;\n  }\n  \n  .Print-module__custom___2D5ye td, .Print-module__custom___2D5ye th {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .Print-module__custom___2D5ye tr:nth-child(even){background-color: #f2f2f2;}\n  \n  .Print-module__custom___2D5ye tr:hover {background-color: #ddd;}\n  \n  .Print-module__custom___2D5ye th {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    background-color: #4CAF50;\n    color: white;\n  }", "",{"version":3,"sources":["Print.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,QAAQ;IACR,SAAS;EACX;;EACA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA,iDAA2B,yBAAyB,CAAC;;EAErD,wCAAkB,sBAAsB,CAAC;;EAEzC;IACE,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;EACd","file":"Print.module.css","sourcesContent":[".custom {\n    font-family: Arial, Helvetica, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  .page {\n    size: A4;\n    margin: 0;\n  }\n  .custom td, .custom th {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .custom tr:nth-child(even){background-color: #f2f2f2;}\n  \n  .custom tr:hover {background-color: #ddd;}\n  \n  .custom th {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    background-color: #4CAF50;\n    color: white;\n  }"]}]);
// Exports
exports.locals = {
	"custom": "Print-module__custom___2D5ye",
	"page": "Print-module__page___1Jvam"
};
module.exports = exports;
