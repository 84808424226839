import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useGridRefresh } from 'tubular-react-common';

import { Global, css } from '@emotion/core';
import Wrapper from '../utils/Wrapper';
import columns from './tableColumns';
import Datepicker from './Datepicker';

const SuperAdmin = ({ storesDataPath }) => {
  const [refresh, setRefreshCounter] = useGridRefresh();
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date()
  });

  const onRangeChange = range => {
    setSelectedRange({ ...selectedRange, ...range });
    // Force refresh table
    setRefreshCounter();
  };

  const requestQueryString = Object.keys(selectedRange)
    .map(key => `${key}=${selectedRange[key].toJSON()}`)
    .join('&');

  const request = `${storesDataPath}?${requestQueryString}`;

  const toolbarButton = new ToolbarOptions({
    topPager: false,
    customItems: [
      <Datepicker
        selectedStartDate={selectedRange.startDate}
        selectedEndDate={selectedRange.endDate}
        onChange={onRangeChange}
      />
    ]
  });

  return (
    <Wrapper>
      <Global
        styles={{
          '.MuiTableCell-root': {
            border: '4px solid #eee'
          },
          '.MuiTablePagination-root': {
            border: 'none'
          }
        }}
      />
      <DataGrid
        gridName="stores"
        columns={columns}
        dataSource={request}
        toolbarOptions={toolbarButton}
      />
    </Wrapper>
  );
};

SuperAdmin.propTypes = {
  storesDataPath: PropTypes.string.isRequired
};

export default SuperAdmin;
