import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

const RangeDatePicker = ({ selectedStartDate, selectedEndDate, onChange }) => {
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs>
            <DatePicker
              variant="inline"
              label="Start Date"
              value={selectedStartDate}
              clearable
              onChange={(date) => onChange({ startDate: date })}
              autoOk
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              minDate={selectedStartDate}
              variant="inline"
              label="End Date"
              clearable
              value={selectedEndDate}
              onChange={(date) => onChange({ endDate: date })}
              autoOk
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

RangeDatePicker.propTypes = {
  selectedStartDate: PropTypes.instanceOf(Date).isRequired,
  selectedEndDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func,
};

RangeDatePicker.defaultProps = {
  onChange: () => {},
};

export default RangeDatePicker;
