import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { get, invoke, isNil } from 'lodash';

import {
  Backdrop,
  Modal,
  Fade,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Alert as AlertUI, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import AutocompleteProduct from './AutocompleteProduct';
import Alert from '../utils/Alert';

import api from './apiClient';

const useStyles = makeStyles({
  card: {
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: 5,
    margin: '1rem 0'
  },
  modalInner: {
    zIndex: '30000 !important',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: 460,
    transform: 'translateY(-50%)',
    overflowY: 'auto',
    outline: 'none'
  },
  modalCard: {
    flex: '1 0 100%',
    margin: '0 auto'
  }
});

function MergeProduct({ product }) {
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [payload, setPayload] = useState({
    fromProductId: product.id
  });
  const styles = useStyles();
  const changeProduct = value => {
    if (!value) return;
    setSelectedProduct(value);
    setPayload(state => ({ ...state, toProductIds: [product.id], fromProductId:  value.value}));
  };
  const attributes = [
    {
      label: 'Map to',
      func: () => (
        <AutocompleteProduct onSelect={changeProduct} valueAs={null} />
      )
    }
  ];

  const toggleModal = open =>
    setIsModalOpen(state => (isNil(open) ? !state : open));
  const closeModal = () => {
    setSelectedProduct(null);
    setIsConfirmed(false);
    toggleModal(false);
  };
  const submitForm = async () => {
    try {
      setIsLoading(true);
      await api.addMapping(payload);
      await api.processMapping();
      setAlert({ message: 'Product Mapped Successfuly' });
      closeModal();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        open={isModalOpen}
        onClose={closeModal}
      >
        <Fade in={isModalOpen}>
          <div className={styles.modalInner}>
            <Card className={styles.modalCard}>
              {isLoading && <LinearProgress />}
              <CardContent>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom>
                      Match to Another Product
                    </Typography>
                    <Typography variant="h5" component="h2">
                      Product #{get(product, 'id')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    {attributes.map(attr => (
                      <Grid
                        container
                        style={{ marginTop: 8, lineHeight: '24px' }}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={5} style={{ lineHeight: '30px' }}>
                          <Typography align="right">{attr.label}:</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          style={{ paddingLeft: 8, lineHeight: '30px' }}
                        >
                          {invoke(attr, 'func', { product })}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {isConfirmed && (
                  <div style={{ marginTop: 32 }}>
                    <Typography
                      variant="h5"
                      style={{ color: 'red' }}
                      gutterBottom
                    >
                      Are you sure?
                    </Typography>
                    <Typography>
                      Are you sure you want to merge Product "{product.name}"
                      and Product "{get(selectedProduct, 'label')}" into Product
                      "{product.name}
                      "? This change can not be undone!
                    </Typography>
                    {!!error && (
                      <AlertUI severity="error" style={{ marginTop: 16 }}>
                        <AlertTitle>Error</AlertTitle>
                        {get(error, 'message', error)}
                      </AlertUI>
                    )}
                  </div>
                )}
              </CardContent>
              <CardActions>
                {!isConfirmed ? (
                  <Button
                    onClick={() => setIsConfirmed(true)}
                    disabled={!selectedProduct || isLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Merge to this product
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={submitForm}
                      disabled={isLoading}
                    >
                      Merge
                    </Button>
                    <Button fullWidth disabled={isLoading} onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          </div>
        </Fade>
      </Modal>
      <div>
        <div className={styles.card}>
          <Button variant="contained" color="primary" onClick={toggleModal}>
            Match to Another Product
          </Button>
        </div>
      </div>
      <Alert
        {...alert}
        style={{
          position: 'fixed',
          right: 20,
          top: 20,
          zIndex: 9999999
        }}
        clear={() => setAlert({})}
      />
    </>
  );
}

MergeProduct.defaultProps = {};

MergeProduct.propTypes = {
  product: PropTypes.shape({ id: PropTypes.number }).isRequired
};

export default MergeProduct;
