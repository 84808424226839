import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';



import { ModalForm } from './ModalForm';



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    overflowY: 'auto',
    position:'absolute',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

  },
  content: {
    padding: 12,
    height: 720,
    overflow: 'scroll'
  },

}));

export const UpdateMultipleProducts = ({
  isClickedMultiple,
  showCheckboxes,
  hideCheckboxes ,
  isClickedCheckbox,
  selectedProducts,
  brandListSearch,
  prodTypes,
  categoriesTypes,
  closeUpdateButton
}) =>{
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [brandSearch, setBrandSearch] = useState('');
  const [brandId, setBrandID] = useState('');
  const [productType, setProductType] = useState('');
  const [productTypeName, setProductTypeName] = useState('');
  const [concentrateType, setConcentrateType] = useState('');
  const [concentrateTypeName, setConcentrateTypeName] = useState('');
  const [canabisType, setCanabisType] = useState('');
  const [canabisTypeName, setCanabisTypeName] = useState('');
  const [categoryIds, setCategoryIds] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [imageFile, setImageFile] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const hideCheckboxesGlobal=()=>{
    closeUpdateButton?( hideCheckboxes(), handleClearState()):null
  }
  useEffect(()=>{
    hideCheckboxesGlobal()
  },[closeUpdateButton])


  const handleClearState =()=>{
    setBrandSearch('')
    setBrandID('')
    setProductType('')
    setProductTypeName('')
    setConcentrateTypeName('')
    setConcentrateType('')
    setCanabisType('')
    setCanabisTypeName('')
    setCategoryIds([])
    setImageFile([])
    handleClose()
  }

  const handleConfirm =() =>{
   const update =  confirm('This change will be applied to all selected products, are you sure you want to continue?')
   if (update == true){
   productMultiUpdate().then(()=>{

    window.document.location.reload()
   })
   }else {
    handleClose()
   }
  }

    async function productMultiUpdate() {

      const token = $('meta[name=csrf-token]').attr('content')
      const  bodyFormData = new FormData();

      bodyFormData.append('authenticity_token', `${token}`)
      bodyFormData.append('multi[multi_ids]', `[${selectedProducts}]`)
      bodyFormData.append('brand_search',  brandSearch?`${brandSearch}`:'')
      bodyFormData.append('multi[brand_id]' , brandId?`${brandId}`:'')
      bodyFormData.append('multi[prod_types_id]', productType?`${productType}`:'');
      bodyFormData.append('multi[product_type]' , concentrateType?`${concentrateType}`:'' );
      bodyFormData.append('multi[is_canabis]', canabisType?`${canabisType}`:'');
      categoryIds.map(c=>{bodyFormData.append('multi[category_ids][]', c? `${c}`:'')})
      imageFile.map(c=>{bodyFormData.append('multi[files][]', c )});



    const res =  await axios.post(`/products/update_multiple`,
    bodyFormData,{
        headers: { 'Content-Type': 'multipart/form-data' }
      })
  }

  const getBrandId = (event, newInputValue) =>{
       newInputValue?(setBrandSearch(newInputValue.label),setBrandID(newInputValue.value)):
       (setBrandSearch(''),setBrandID(''))
     }

  const getProdType = (event, newInputValue) =>{
    newInputValue?(setProductType(newInputValue.id), setProductTypeName(newInputValue.name)):
    (setProductType(''), setProductTypeName(''))
  }


  const getConcentrat = (event, newInputValue) =>{
    newInputValue?(setConcentrateType(newInputValue.type), setConcentrateTypeName(newInputValue.name)):
    (setConcentrateType(''), setConcentrateTypeName(''))
  }

  const getCanabisType = (event, newInputValue) =>{
    newInputValue?(setCanabisType(newInputValue.id), setCanabisTypeName(newInputValue.type)):
    (setCanabisType(''), setCanabisTypeName(''))
  }

  const getCategory = (id, name) =>{
    const newArr = [...categoryIds];
     const index = newArr.findIndex(item=> item === id)
    if(index === -1){
      newArr.push(id)
    }
    else{
      newArr.splice(index, 1)
    }
    setCategoryIds(newArr)

          }
      const  getImageFile=(files)=>{
            setImageFile(files)

          }

 return <div>
              <div  style={{position: 'absolute', right: 0, top: 20, marginBottom: 10}}>

                {!isClickedMultiple ? <div  >

                <button  style={{display: ''}}    className="pull-right" type="button" onClick={showCheckboxes} className="btn btn-success btn-rounded">Update Multiple Products</button></div> :

                 (isClickedMultiple && isClickedCheckbox)?

                <div >
                <button type="button" onClick={hideCheckboxes} className="btn btn-rounded cancel-multiple-btn" style={{display: ''}}>Cancel Update</button>
                <button type="button" onClick = {handleOpen}   className="btn btn-primary btn-rounded update-attributes-btn" style={{display: ''}}
              data-toggle="modal" data-target="#updateAttributesModal"
              >Update Attributes</button>
              <button type="button"   className="btn btn-success btn-rounded">Update Multiple Products</button>
              </div>
               :

              (isClickedMultiple)? <div >

                  <button type="button" onClick={hideCheckboxes} className="btn btn-rounded cancel-multiple-btn" style={{display: null}}>Cancel Update</button>
                   <button type="button"  className="btn btn-success btn-rounded">Update Multiple Products</button></div>: null }

            </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} >
            <div >
            <h2 style = {{marginLeft: 10}} id="transition-modal-title">Select attributes to update for the selected</h2>
            <div className={classes.content}>
            <ModalForm
            brandListSearch={brandListSearch}
            selectedBrand={brandSearch}
            productTypeName={productTypeName}
            concentrateTypeName={concentrateTypeName}
            canabisTypeName={canabisTypeName}
            getBrandId={getBrandId}
            getProdType={getProdType}
            getConcentrat={getConcentrat}
            getCanabisType={getCanabisType}
            getCategory={getCategory}
            categoryIds={categoryIds}
            getImageFile={getImageFile}
            imageFile={imageFile}
            prodTypes={prodTypes}
            categoriesTypes={categoriesTypes}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            handleClearState={handleClearState}
            />
            </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
          }

  UpdateMultipleProducts.propTypes = {
    isClickedMultiple: PropTypes.bool,
  showCheckboxes: PropTypes.func,
  hideCheckboxes: PropTypes.func,
  isClickedCheckbox: PropTypes.bool,
  selectedProducts: PropTypes.array,
  brandListSearch: PropTypes.arrayOf(PropTypes.object),
  prodTypes: PropTypes.arrayOf(PropTypes.object),
  categoriesTypes: PropTypes.arrayOf(PropTypes.object),
 };

UpdateMultipleProducts.defaultProps = {
  isClickedMultiple: false,
  showCheckboxes:  () => {},
  hideCheckboxes:  () => {},
  isClickedCheckbox: false,
  selectedProducts: [],
  brandListSearch: [],
  prodTypes: [],
  categoriesTypes: [],
};
