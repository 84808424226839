import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ToolbarOptions } from 'tubular-react';
import { useTbTable } from 'tubular-react-common';

import { get } from 'lodash';

import Button from '@material-ui/core/Button';

import DataGrid from '../utils/DataGrid';
import columns from './InventoryIntakeColumns';
import Wrapper from '../Wrapper';
import RangeDatePicker from '../utils/Datepicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import metrc from './apiClient';

import Alert from '../utils/Alert';

import '../styles/IntakeInventory.css';

import socket, { subscribeToInventory } from './socket';

const licenseNumber = get(gon, 'store.metrc_license_number');
const allow_sync_metrc_transfer = get(gon, 'store.allow_sync_metrc_transfer');

const DeliveryStatus = ({ delivery }) => {
  const succeed = !!delivery.received_date_time;

  const styles = {
    width: 25,
    height: 25,
    borderRadius: 30,
    margin: 5,
    backgroundColor: succeed ? 'green' : 'red',
  };

  return <div style={styles} />;
};

const ITEMS_PER_PAGE = 25;

const InventoryIntake = ({ onDateRangeChange, dateRange, hidden }) => {

  const [tableData, setTableData] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState({ message: null, severity: 'success' });
  const [status, setStatus] = useState(null);


  const [checked, setChecked] = React.useState(true);

  const updateStatus = ({ msg, severity }) => {
    setStatus({ msg, severity });
    setTimeout(() => {
      setStatus(() => {
        return null;
      });
    }, 6000);
  };

  const handleChange = async (event,manifest_id) => {
    let isChecked = event.target.checked;
    setChecked(isChecked);
    await metrc.allowManifestForSync({ manifest_id, isChecked });
  };

  const onHideOrUnideClick = async (manifest_id, is_hidden) => {
    try {
      await metrc.hideOrUnhideManifest({ manifest_id, is_hidden });
      forceFetchData();
      updateStatus({ severity: 'success', msg: 'Manifest already set as hidden' });
    } catch (err) {
      const msg = get(err, 'response.data.errors', [
        get(err, 'response.data.error.message', 'Request failed'),
      ]).join(' ');
      updateStatus({ severity: 'error', msg });
    }
  };
  
  const processData = (item) => ({
    ...item,
    //received_date_time: item.received_date_time !== null ? moment(item.received_date_time).format('MM/DD/YYYY') : item.received_date_time,
    received_date_time: item.received_date_time || '-',
    status_component: (() => <DeliveryStatus delivery={item} />)(),
    actions: (() => {    
        return (
          <Button
            variant="outlined"
            color="primary"
            href={`/metrc/intake/delivery/${item.id}`}
          >
            View Details
          </Button>
        );
    })(),
    sync: (()=> {
      if (item.received_date_time === null && allow_sync_metrc_transfer && !hidden) {
        return (
          <FormControlLabel
              control={
                  <Checkbox
                      id="sync_manifest"
                      name="sync_manifest"
                      defaultChecked={item.allow_sync}
                      //value={item.id}
                      onChange={(e) => handleChange(e, item.id)}
                  />
              } 
          />
        );
      }
    })(),
    hide: (()=> {
      if (item.received_date_time === null && allow_sync_metrc_transfer) {
        return (
          <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onHideOrUnideClick(item.id, !hidden)}
                  style={{ marginRight: 6, marginBottom: 6 }}
                >
                    {hidden  ? 'UnHide' : 'Hide'}
                  </Button>
        );
      }
    })()
  });


  const tbTableInstance = useTbTable(columns, tableData, {
    pagination: {
      itemsPerPage: ITEMS_PER_PAGE,
      page: 0,
    },
  });

  const toolbarOptions = new ToolbarOptions({
    title: 'Inventory',
    rowsPerPageOptions: [],
    itemsPerPage: ITEMS_PER_PAGE,
    advancePagination: false,
    searchText: false,
    topPager: false,
    customItems: [
      <RangeDatePicker
        onChange={onDateRangeChange}
        selectedStartDate={dateRange.startDate}
        selectedEndDate={dateRange.endDate}
      />,
    ],
  });

  async function fetchData() {
    const {
      data: { list, pages },
    } = await metrc.inventory({
      licenseNumber,
      dateRange,
      hidden,
      page: page + 1,
    });
    setTableData(list.map(processData));
    setCount(ITEMS_PER_PAGE * pages);
  }

  useEffect(() => {
    fetchData();
  }, [dateRange, page]);

  useEffect(() => {
    subscribeToInventory(async () => {
      await fetchData();
      setAlert({ severity: 'success', message: 'New Inventory' });
    });
  }, []);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const forceFetchData = async () => {
    await fetchData();
    forceRefresh();
  };

  const forceRefresh = () => setRefreshCount((state) => state + 1);

  return (
    <Wrapper>
      <Alert
        message={alert.message}
        severity={alert.severity}
        clear={() => setAlert({})}
      />
      <DataGrid
        gridName="stores"
        columns={columns}
        toolbarOptions={toolbarOptions}
        tbTableInstance={tbTableInstance}
        count={count}
        page={page}
        onChangePage={onPageChange}
      />
    </Wrapper>
  );
};

InventoryIntake.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default InventoryIntake;
