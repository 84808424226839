import React from 'react';
import useTubular from './useTubular';

const useTbTable = (initColumns, source, tubularOptions) => {
  const tubular = useTubular(initColumns, source, tubularOptions);
  const [getMultiSort, setMultiSort] = React.useState(false);
  const handleKeyDown = (event) => {
    if (event.key === 'Control' && !getMultiSort) {
      setMultiSort(true);
    }
  };
  const handleKeyUp = (event) => {
    if (event.key === 'Control' && getMultiSort) {
      setMultiSort(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [getMultiSort]);
  return {
    api: {
      ...tubular.api,
      sortColumn: (colName) => {
        tubular.api.sortColumn(colName, getMultiSort);
      },
    },
    state: tubular.state,
  };
};

export default useTbTable;
