import { get, isNil, find } from 'lodash';

export function convertArrayOfObjectsToCSV(array, columns) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = columns.map(c => c.selector || null).filter(i => !isNil(i));
  const labels = columns.filter(i => !isNil(i.selector)).map(c => c.name);

  result = '';
  result += labels.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;

    keys.forEach(key => {
      let value;
      if (ctr > 0) result += columnDelimiter;

      const exportFunc = get(find(columns, { selector: key }), 'exportFunc');

      if (exportFunc) value = exportFunc(item);
      else value = isNil(item[key]) ? '-' : item[key];

      result += value;

      ctr++;
    });

    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array, columns) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array, columns);
  if (csv == null) return;

  const filename = 'CSV.csv';

  const universalBOM = '\uFEFF';

  link.setAttribute(
    'href',
    'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csv)
  );
  link.setAttribute('download', filename);
  link.click();
}
