import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import addDays from 'date-fns/addDays';

import { isUndefined } from 'lodash';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

const RangeDatePicker = ({
  selectedStartDate,
  selectedEndDate,
  onChange,
  startDateAs,
  endDateAs,
  startProps,
  endProps,
}) => {
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs>
            <DatePicker
              variant="inline"
              label="Start Date"
              value={selectedStartDate}
              clearable
              onChange={(date) => onChange({ [startDateAs]: date })}
              autoOk
              {...startProps}
            />
          </Grid>
          {isUndefined(selectedEndDate) ? null : (
            <Grid item xs>
              <DatePicker
                minDate={selectedStartDate}
                variant="inline"
                label="End Date"
                clearable
                value={selectedEndDate}
                onChange={(date) => onChange({ [endDateAs]: date })}
                autoOk
                {...endProps}
              />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

RangeDatePicker.propTypes = {
  selectedStartDate: PropTypes.instanceOf(Date).isRequired,
  selectedEndDate: PropTypes.instanceOf(Date).isRequired,
  startDateAs: PropTypes.string,
  endDateAs: PropTypes.string,
  onChange: PropTypes.func,
  startProps: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
  endProps: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
};

RangeDatePicker.defaultProps = {
  onChange: () => {},
  startDateAs: 'startDate',
  endDateAs: 'endDate',
  endProps: {},
  startProps: {},
};

export default RangeDatePicker;
