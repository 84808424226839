import styled from '@emotion/styled';

import CardMaterial from '@material-ui/core/Card';
import TableMaterial from '@material-ui/core/Table';
import TypographyMaterial from '@material-ui/core/Typography';
import StepperMaterial from '@material-ui/core/Stepper';

import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .MuiCard-root {
    overflow: auto;
  }
  @media (min-width: 769px) {
    align-items: center;

    width: 100vw;
    height: 100vh;
  }
`;
export const Typography = styled(TypographyMaterial)(props => ({
  color: props.color || 'inherit',
  margin: props.margin || '',
  textAlign: props.align || ''
}));

export const Card = styled(CardMaterial)`
  max-width: 600px;

  min-height: 90%;

  padding: 15px;

  width: 100%;
  flex: 1;

  overflow-x: auto !important;
  @media (min-width: 769px) {
    height: 100%;
    max-height: 450px;
  }
`;

export const CardHeader = styled(Grid)`
  align-items: flex-start;

  @media (min-width: 769px) {
    padding: 15px 0;
    align-items: center;
  }
`;

export const CardLogo = styled.div`
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }

  img {
    object-fit: contain;
  }
`;

export const Chip = styled.div(props => ({
  padding: '4px 16px',
  borderRadius: '100px',

  backgroundColor: props.bgColor || '#eee',
  color: props.color || '#212121',

  marginLeft: 'auto'
}));

export const Table = styled(TableMaterial)``;

export const TableWrapper = styled.div`
  height: 500px;
  overflow: auto;
`;

export const Stepper = styled(StepperMaterial)`
  .MuiStep-horizontal {
    .MuiStepLabel-horizontal {
      display: block;
      span {
        text-align: center;
      }
      .MuiStepLabel-iconContainer {
        padding: 8px;
        display: flex;
        justify-content: center;
        div {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 34px 0 !important;
  }
`;

export const GridLogo = styled(Grid)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
