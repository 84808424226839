import { get } from 'lodash';
import io from 'socket.io-client';

function getSocket() {
  const apiUrl = get(gon, 'metrc_config.api_url');
  const TOKEN = get(gon, 'metrc_config.user_token');

  return io(`${apiUrl}/dashboard`, {
    path: '/socket.io',
    query: {
      token: TOKEN.substring(6),
    },
  });
}

function subscribeToInventory(cb) {
  const storeId = get(gon, 'store.id');

  getSocket().on(`tr_add_${storeId}`, cb);
}

export default getSocket;
export { subscribeToInventory };
