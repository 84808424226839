import React from 'react';
import PropTypes from 'prop-types';

import { get, invoke } from 'lodash';

import { CircularProgress, Typography } from '@material-ui/core';

import formatDistance from 'date-fns/formatDistance';

const DeliveryDetails = ({ info }) => {
  if (!info) return null;

  const attributes = [
    {
      label: 'Manifest #',
      key: 'transfer_id',
    },
    {
      label: 'Shipper Facility',
      key: 'shipper_facility_name',
    },
    {
      label: 'ETA',
      value: (val) =>
        formatDistance(new Date(val.estimated_arrival_date_time), new Date()),
    },
    {
      label: 'Packages',
      key: 'package_count',
    },
  ];

  return (
    <div
      style={{
        margin: '1rem 0',
      }}
    >
      {info ? (
        attributes.map((attr) => (
          <Typography key={attr.key}>
            <strong>{attr.label}</strong>:<span> </span>
            {attr.value
              ? invoke(attr, 'value', info)
              : get(info, attr.key, '-')}
          </Typography>
        ))
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

DeliveryDetails.propTypes = {
  info: PropTypes.shape({
    transfer_id: PropTypes.number.isRequired,
    shipper_facility_name: PropTypes.string.isRequired,
    estimated_arrival_date_time: PropTypes.string,
    package_count: PropTypes.number,
  }).isRequired,
};

export default DeliveryDetails;
