import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Lodash
import { get, invoke, isEmpty } from 'lodash';

// Material
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import LinearProgress from '@material-ui/core/LinearProgress';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import metrc from './apiClient';

const useStyles = makeStyles({
  modalRoot: {
    zIndex: '30000 !important',
  },
  modalInner: {
    zIndex: '30000 !important',

    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,

    margin: '0 auto',
    maxWidth: 460,

    transform: 'translateY(-50%)',
    overflowY: 'auto',
  },
  modalCard: {
    flex: '1 0 100%',
    margin: '0 auto',
  },
});

const InventoryUpdateQuantity = ({
  product,
  logReasons,
  onClose,
  user,
  submit,
  showAlert,
}) => {
  const classes = useStyles();
  const [labels, setLabels] = useState(product.labels);
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [updateForm, setUpdateForm] = useState({    
    providerId: product.provider_id,
    productId: product.product_id,
    reasonId: null,
    quantity: product.quantity,
    label: undefined,
    changedBy: user.id,
  });

  const changeUpdateForm = (form) => {
    setUpdateForm((state) => ({
      ...state,
      ...form,
    }));
  };

  const fetchLabel = async () => {
    const {
      data: { labels },
    } = await metrc.getProductLabel(
      _.pick(updateForm, ['productId', 'quantity'])
    );

    setLoading(false);
    setShowMsg(true);
    return false;
  };

  const submitForm = async (e) => {
    e.preventDefault();

   /* if (!showMsg) {
      fetchLabel();
      return;
    } */

    if (!updateForm.reasonId) {
      showAlert({ severity: 'error', msg: 'Reason is required' });
      return;
    }

    setLoading(true);

    await metrc.updateQuantity(updateForm);

    setLoading(false);
    submit();
    onClose();
  };

  const attributes = [
    {
      label: 'Product',
      func: ({ product }) => <Typography>{product.product_name}</Typography>,
    },
    {
      label: 'Current Quantity',
      func: ({ product }) => <Typography>{product.quantity}</Typography>,
    },
    {
      label: 'Reduce by',
      func: ({ product }) => (
        <TextField
          type="number"
          inputProps={{ min: 0 }}
          defaultValue={product.quantity}
          onChange={(e) =>
            changeUpdateForm({ quantity: Number(e.target.value) })
          }
        />
      ),
    },
    {
      label: 'Reason',
      func: ({ logReasons }) => (
        <>
          <Select
            style={{ minWidth: 170 }}
            value={updateForm.reasonId}
            onChange={(e) => changeUpdateForm({ reasonId: e.target.value })}
          >
            {logReasons.map((reason) => (
              <MenuItem value={reason.id}>{reason.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Required</FormHelperText>
        </>
      ),
    },
    {
      label: 'METRC Label',
      func: () => (
        <>
          <Select
            style={{ minWidth: 179 }}
            onChange={(e) => changeUpdateForm({ label: e.target.value })}
            disabled={isEmpty(labels)}
          >
            {labels.map((label) => (
              <MenuItem value={label}>{label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Optional</FormHelperText>
        </>
      ),
    },
  ];

  return (
    <Modal
      aria-labelledby="update-category-title"
      aria-describedby="update-category-desc"
      open={!!product}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modalRoot}
    >
      <Fade in={!!product}>
        <div className={classes.modalInner}>
          <Card className={classes.modalCard}>
            {loading && <LinearProgress />}
            <CardContent>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    id="update-category-title"
                  >
                    Update Quantity
                  </Typography>
                  <Typography variant="h5" component="h2">
                    Product #{get(product, 'product_id')}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <div style={{ marginTop: 32 }}>
                <form onSubmit={submitForm} name="update-quantity">
                  {attributes.map((attr) => (
                    <Grid
                      container
                      style={{ marginTop: 8, lineHeight: '24px' }}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={5} style={{ lineHeight: '30px' }}>
                        <Typography align="right">{attr.label}:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{ paddingLeft: 8, lineHeight: '30px' }}
                      >
                        {invoke(attr, 'func', { product, logReasons } || {})}
                      </Grid>
                    </Grid>
                  ))}
                </form>
              </div>
              {showMsg && (
                <div style={{ marginTop: 32 }}>
                  <Typography
                    variant="h5"
                    style={{ color: 'red' }}
                    gutterBottom
                  >
                    Are you sure?
                  </Typography>
                  <Typography>
                    This product quantity will be updated for Label{' '}
                    {updateForm.label || labels[0]}. You are required to update
                    this label on Metrc. <a href="#">Learn how to do it here</a>
                  </Typography>
                </div>
              )}
            </CardContent>
            <CardActions style={{ marginTop: 16 }}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  form="update-quantity"
                >
                  {showMsg ? 'Yes, Update Quantity' : 'Update Quantity'}
                </Button>
              </FormControl>
            </CardActions>
          </Card>
        </div>
      </Fade>
    </Modal>
  );
};

InventoryUpdateQuantity.propTypes = {
  product: PropTypes.shape({
    product_name: PropTypes.string,
    product_id: PropTypes.number,
    quantity: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  logReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  user: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
};

export default InventoryUpdateQuantity;
