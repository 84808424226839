import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const OrderOriginForm = ({ onSubmit, classes }) => {
  const [orderOrigin, setOrderOrigin] = useState('');

  const onInputChange = value => {
    setOrderOrigin(value);
  };

  const createOrderOrigin = async e => {
    e.preventDefault();
    if (!orderOrigin.trim()) return;

    const { data: newOrderOrigin } = await axios.post('/order_origins', {
      origin: orderOrigin.trim()
    });
    setOrderOrigin('');

    onSubmit(newOrderOrigin);
  };

  return (
    <div>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={createOrderOrigin}
      >
        <FormGroup row>
          <TextField
            value={orderOrigin}
            onChange={({ target }) => onInputChange(target.value)}
            label="Create new Order Origin"
          />
          <Button type="submit" color="primary" disabled={!orderOrigin}>
            Create <AddIcon />
          </Button>
        </FormGroup>
      </form>
    </div>
  );
};

OrderOriginForm.propTypes = {
  onSubmit: PropTypes.func,
  classes: PropTypes.shape({
    form: PropTypes.string
  })
};

OrderOriginForm.defaultProps = {
  onSubmit: () => {},
  classes: {}
};

export default memo(OrderOriginForm);
