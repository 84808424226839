import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { merge } from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';

const OrderAssignDriver = ({ order, orderDriver, drivers }) => {
  const [selectedDriver, setDriver] = useState(orderDriver || null);
  const [isLoading, setLoading] = useState(false);

  if (!['Confirmed'].includes(order.status)) return null;

  const formStyle = {};
  if (isLoading) {
    merge(formStyle, {
      opacity: '.5'
    });
  }

  const onDriverSelect = (e, value) => {
    setDriver(value);
  };

  const onFormSubmit = async e => {
    try {
      e.preventDefault();
      setLoading(true);

      await axios.put(`/orders/${order.id}/driver`, {
        new_driver_id: selectedDriver.id
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getSelectedDriver = (driver, selected) => driver.id === selected.id;

  return (
    <div style={{ position: 'relative' }}>
      <form onSubmit={onFormSubmit} style={formStyle}>
        <Autocomplete
          options={drivers}
          id={`select-driver-for-${order.id}`}
          value={selectedDriver}
          getOptionSelected={getSelectedDriver}
          getOptionLabel={driver => `${driver.first_name} ${driver.last_name}`}
          onChange={onDriverSelect}
          style={{ width: 170 }}
          renderInput={params => (
            <TextField {...params} label="Driver" margin="normal" fullWidth />
          )}
          autoSelect
          autoHighlight
        />
        <Button
          style={{ marginLeft: 'auto', marginRight: 0, display: 'block' }}
          color="primary"
          type="submit"
        >
          Assign
        </Button>
      </form>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
    </div>
  );
};

OrderAssignDriver.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    })
  ).isRequired,
  orderDriver: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired
};

export default OrderAssignDriver;
